import {
  BraceType,
  FigureSymbol,
  FigureSymbolType,
  SymbolSnapPoint
} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {ReferenceSign, ReferenceSignMarkerPrototype} from '@/api/models/referenceSign.model';

export interface CreateSymbolEvent {
  guid: string;
  figureGuid: string;
  symbolType: FigureSymbolType;
  x1: number;
  y1: number;
  x2?: number;
  y2?: number;
  dashed?: boolean;
  handle1x?: number;
  handle1y?: number;
  handle2x?: number;
  handle2y?: number;
  braceType?: BraceType;
  snapPoints?: SymbolSnapPoint[];
}

export interface CreateSymbolsEvent {
  symbols: CreateSymbolEvent[];
}

export interface InsertElementsEvent {
  figureGuid: string;
  referenceSignMarker: ReferenceSignMarkerPrototype[];
  userConsent: UserConsent;
  offset: number;
}

export enum UserConsent {
  NOT_ASKED = "NOT_ASKED",
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED"
}

export interface SymbolsCreatedVmUpdate {
  symbols: FigureSymbol[];
}

export interface UpdateSymbolEvent {
  guid: string;
  x1?: number;
  y1?: number;
  x2?: number;
  y2?: number;
  dashed?: boolean;
  handle1x?: number;
  handle1y?: number;
  handle2x?: number;
  handle2y?: number;
  underlined?: boolean;
  braceType?: BraceType;
  referenceSigns?: string[];
  snapPoints?: SymbolSnapPoint[];
}

export interface SymbolUpdatedVmUpdate {
  symbol: FigureSymbol;
}

export interface SymbolDeletedVmUpdate {
  guid: string;
}

export interface InsertFigureElementVmUpdate {
  askForConsent: boolean;
  referenceSignMarker: FigureSymbol[];
  updatedReferenceSignList: ReferenceSign[];
}