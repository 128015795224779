<template>
  <div class="application" id="application">
    <Splitpanes ref="topLevelSplitPane"
                :auto-equalize="false"
                :dbl-click-splitter="false"
                :push-other-panes="false"
                @splitter-click="onSplitterClick($event)"
                @resize="onResize($event)"
                @resized="onResized($event)"
                class="default-theme application-main-splitpanes">
      <Pane :size="leftSplitPaneSizeComputed" min-size="0">
        <splitpanes horizontal
                    class="default-theme application-left-splitpanes"
                    :push-other-panes="false"
                    @resize="onResizeLeft($event)"
                    @splitter-click="onSplitterHorizontalClick($event)">
          <pane class="document-structure-tree-pane">
            <DocumentStructureTree/>
          </pane>
          <pane ref="search" class="search-pane" v-bind:class="{scrollx: scrollSearch()}">
            <!-- mainTab can be: APPLICATION_DOCUMENTS, LIBRARY -->
            <!-- searchSpaceTab for searchApplicationDocuments can be: MAIN_CLAIM_AND_EXPLANATION, ALL_FIGURES -->
            <!-- searchSpaceTab for searchSpace can be: TERMS, TEXT_BLOCK_A, TEXT_BLOCK_B -->
            <Search :mainTab="mainTab" :searchSpaceTab="searchSpaceTab"/>
          </pane>
        </splitpanes>
      </Pane>
      <Pane :size="documentEditorPaneSizeComputed" min-size="10">
        <ApplicationEditor
          @mainEditorScrolled="onEditorScroll"
          ref="applicationEditorInstance"/>
      </Pane>
      <Pane v-if="showDocumentOnly"
            :size="this.rightSplitPaneSizeComputed"
            min-size="0">
        <splitpanes horizontal
                    class="default-theme application-right-splitpanes"
                    @splitter-click="onSplitterHorizontalClick($event)"
                    :push-other-panes="false"
        >
          <pane min-size="0">
            <ReferenceSignList
              :application-guid="applicationGUID"
              :application-editor-instance="applicationEditorInstance"
              :edit-reference-signs-mode="EditReferenceSignMode.EDIT_ENABLED"
              :drag-mode="DragMode.DRAG_DROP_DISABLED"
              :show-apply-reference-sign-button="true"
              :show-hightlight-reference-sign-button="true"
            />
          </pane>
          <pane min-size="0">
            <ApplicationFigureList
              :application-guid="applicationGUID"
              :figures="this.figures"
              :onEditFigureClicked="onEditFigureClicked"
            />
          </pane>
        </splitpanes>
      </Pane>
      <Pane v-if="!showDocumentOnly"
            min-size="10"
            :size="figureEditorPaneSizeComputed"
            class="figure-editor-pane"
            ref="figureEditorPane">
        <FigureEditor
          :key="applicationGUID"
          :application-guid="applicationGUID"
          :figure-guid="this.initialFigureGuid"
          :figures="figures"
          @figureChanged="onSelectedFigureChanged"
        />
      </Pane>
      <Pane v-if="!showDocumentOnly"
            :max-size="30"
            :size="this.rightSplitPaneSizeComputed"
            ref="rightSplitpane">
        <splitpanes horizontal
                    class="default-theme figure-main-splitpanes"
                    :push-other-panes="false"
                    @initialHeight="onRightSplitpaneReady"
        >
          <pane min-size="0" class="reference-sign-list-pane">
            <ReferenceSignList
              :application-guid="applicationGUID"
              :application-editor-instance="null"
              :edit-reference-signs-mode="EditReferenceSignMode.EDIT_ENABLED"
              :drag-mode="DragMode.DRAG_DROP_ENABLED"
              :show-apply-reference-sign-button="true"
              :show-hightlight-reference-sign-button="true"
            />
          </pane>
          <pane min-size="0" class="reference-sign-list-pane">
            <DraggableSymbolList
              :symbolHeader="$t('symbols')"
              :symbolsList="symbolsList"
              :disabled="this.actualFigureGUID === null"
            />
          </pane>
          <pane min-size="0" class="reference-sign-list-pane">
            <DraggableSymbolList
              :symbolHeader="$t('helpLines')"
              :symbolsList="helpLinesList"
              :disabled="this.actualFigureGUID === null"
            />
          </pane>
          <pane min-size="0" :size="this.initialApplicationFigureListHeight">
            <ApplicationFigureList
              :application-guid="applicationGUID"
              :figures="this.figures"
              :onEditFigureClicked="onEditFigureClickedInSplitview"
              @ready="onApplicationFigureListReady"
            />
          </pane>
        </splitpanes>
      </Pane>
    </Splitpanes>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import ReferenceSignList, {DragMode, EditReferenceSignMode} from '@/components/ReferenceSignList.vue';
import ApplicationEditor, {ApplicationEditor as ApplicationEditorClass} from '@/components/ApplicationEditor.vue';
import ApplicationModule from '@/store/modules/ApplicationModule';
import DocumentStructureTree from '@/components/DocumentStructureTree.vue';
import Search from '@/components/Search.vue';
import {SearchMainTabs, SearchSpace} from '@/api/models/search.model';
import ApplicationFigureList, {ApplicationFigureListReadyEvent} from '@/components/ApplicationFigureList.vue';
import {RouteLocationNormalized} from 'vue-router';
import {
  ApplicationViewSplitMode,
  ApplicationViewSplitState,
  DocumentAndFigureSplitStateSizes,
  DocumentAndFiguresSplitState,
  DocumentOnlySplitState,
  DocumentOnlySplitStateSizes,
  PaneSizes
} from '@/store/models/application.model';
import Figure from '@/views/Figure.vue';
import FigureEditor from '@/components/FigureEditor.vue';
import DraggableSymbolList from '@/components/DraggableSymbolList.vue';
import ApplicationFigureModule, {selectNewFigure} from '@/store/modules/ApplicationFigureModule';
import {ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';
import {debounce} from 'lodash';
import {UpdateDocumentAndFiguresSplitViewState} from '@/api/models/application.model';
import AuthModule from '@/store/modules/AuthModule';
import {EditorScrollEvent} from '@/components/ApplicationEditorView.vue';
import Splitpanes, {Splitpanes as SplitpanesClass} from '@/components/splitpane/Splitpanes.vue';
import Pane, {Pane as PaneClass} from '@/components/splitpane/Pane.vue';
import {useDefaultErrorHandling} from '@/errorHandling';
import {DRAGGABLE_HELP_LINE_LIST, DRAGGABLE_SYMBOL_LIST, DraggableSymbolEntry} from '@/store/models/figure.model';
import {routeTo} from '@/util/router.util';

const Splitters = {
  left: 1,
  rightWhenDocumentOnly: 2,
  rightWhenDocumentAndFigures: 3
}

@Component(
  {
    name: 'application',
    components: {
      Pane,
      Splitpanes,
      DraggableSymbolList,
      FigureEditor,
      Figure,
      DocumentStructureTree,
      ApplicationEditor,
      ReferenceSignList,
      Search,
      ApplicationFigureList
    }
  })
class Application extends Vue {

  @Prop({required: true})
  private applicationGuid!: string;

  private initialFigureGuid: string | null = null;

  private actualFigureGuid: string | null = null;

  @Ref('topLevelSplitPane')
  private readonly splitpane!: SplitpanesClass;

  @Ref('search') private searchPane!: { $el: HTMLElement };

  @Ref('applicationEditorInstance')
  private applicationEditorInstance!: ApplicationEditorClass;

  @Ref('figureEditorPane')
  private readonly figureEditorSplitPane!: PaneClass;

  @Ref('rightSplitpane')
  private readonly rightSplitpane!: PaneClass;

  private panesResizing = false;
  private initialPaneSizesOnResizing: PaneSizes | null = null;

  private scrollbarSize = 18;          // Estimated thickness of scroll bar

  private searchPaneSize = 50;
  private searchPaneSizePx = (this.searchPaneSize / 100) * window.innerHeight;

  // Search
  private mainTab = SearchMainTabs.LIBRARY;
  private searchSpaceTab = SearchSpace.TERM;
  private searchScrollActive = false;

  private lastScrollPosition: {scrollTop: number; scrollLeft: number } = {scrollLeft: 0, scrollTop: 0};

  private initialOverallHeight: number | null = null;
  private applicationFigureListHeaderHeightPx: number | null = null;
  private initialApplicationFigureListHeightPercent: number | null = null

  private debounceSetDocumentAndFigureSplitState = debounce((param: UpdateDocumentAndFiguresSplitViewState) =>
                                                              ApplicationModule.updateDocumentAndFigureSplitViewState(param), 1000);


  private onEditorScroll(event: EditorScrollEvent) {
    this.lastScrollPosition = event;
  }

  private onRightSplitpaneReady(initialHeight: number) {
    this.initialOverallHeight = initialHeight;
  }

  private onApplicationFigureListReady(event: ApplicationFigureListReadyEvent) {
    this.applicationFigureListHeaderHeightPx = event.headerHeightPx;
  }

  private onEditFigureClicked(figureGuid: string) {
    routeTo(`${this.applicationGuid}/figure/${figureGuid}`);
  }

  private onEditFigureClickedInSplitview(figureGuid: string) {
    this.initialFigureGuid = figureGuid;
  }

  private onSelectedFigureChanged(figureGuid: string | null) {
    this.actualFigureGuid = figureGuid;
    if (this.actualFigureGuid === this.initialFigureGuid) {
      this.initialFigureGuid = this.actualFigureGuid;
    }
  }

  private get initialApplicationFigureListHeight() {
    if (this.initialApplicationFigureListHeightPercent !== null) {
      return this.initialApplicationFigureListHeightPercent;
    }

    if (this.initialOverallHeight !== null && this.applicationFigureListHeaderHeightPx !== null) {
      this.initialApplicationFigureListHeightPercent = ((this.applicationFigureListHeaderHeightPx / this.initialOverallHeight)) * 100;
      return this.initialApplicationFigureListHeightPercent;
    }
    return 0;
  }

  private get applicationViewSplitState(): ApplicationViewSplitState {
    return ApplicationModule.applicationViewSplitState;
  }

  private get paneSizes(): PaneSizes {
    return ApplicationModule.paneSizes;
  }

  get symbolsList(): DraggableSymbolEntry[] {
    return DRAGGABLE_SYMBOL_LIST;
  }

  get helpLinesList(): DraggableSymbolEntry[] {
    return DRAGGABLE_HELP_LINE_LIST;
  }

  @Watch('applicationViewSplitState', {immediate: true})
  private onApplicationViewSplitStateChange(newValue: ApplicationViewSplitState): void {
    this.layoutPanes(newValue);
  }

  private layoutPanes(newValue: DocumentOnlySplitState | DocumentAndFiguresSplitState) {
    switch (newValue.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY:
        this.updatePanesForDocumentOnly(newValue);
        break;
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES:
        this.updatePanesForDocumentAndFigures(newValue);
        break;
    }
  }

  private calculateDocumentEditorPaneSize(leftPaneSize: number, leftPaneMinimized: boolean,
                                          rightPaneSize: number, rightPaneMinimized: boolean) {

    const effectiveLeftPaneSize = this.computePaneSizeConsideringMinimize(leftPaneSize, leftPaneMinimized);
    const effectiveRightPaneSize = this.computePaneSizeConsideringMinimize(rightPaneSize, rightPaneMinimized);
    return 100 - effectiveLeftPaneSize - effectiveRightPaneSize;
  }

  private updatePanesForDocumentOnly(newValue: DocumentOnlySplitState) {
    switch (this.paneSizes.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY: // nothing
        break;
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES: {
        const newPaneSize: DocumentOnlySplitStateSizes = {
          mode: ApplicationViewSplitMode.DOCUMENT_ONLY,
          leftPaneSize: this.paneSizes.leftPaneSize,
          leftPaneMinimized: this.paneSizes.leftPaneMinimized,
          rightPaneSize: this.paneSizes.rightPaneSize,
          rightPaneMinimized: this.paneSizes.rightPaneMinimized,
          documentEditorPaneSize: this.calculateDocumentEditorPaneSize(this.paneSizes.leftPaneSize, this.paneSizes.leftPaneMinimized,
                                                                       this.paneSizes.rightPaneSize, this.paneSizes.rightPaneMinimized)

        }
        ApplicationModule.setPaneSizes(newPaneSize);
      }
        break;
      default:
        throw 'illegal application split mode';
    }
  }

  private updatePanesForDocumentAndFigures(newValue: DocumentAndFiguresSplitState) {
    switch (this.paneSizes.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY: {
        const effectiveMiddlePaneSize = this.calculateDocumentEditorPaneSize(this.paneSizes.leftPaneSize, this.paneSizes.leftPaneMinimized,
                                                                             this.paneSizes.rightPaneSize,
                                                                             this.paneSizes.rightPaneMinimized);
        const newPaneSizes: DocumentAndFigureSplitStateSizes = {
          mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES,
          leftPaneSize: this.paneSizes.leftPaneSize,
          leftPaneMinimized: this.paneSizes.leftPaneMinimized,
          rightPaneSize: this.paneSizes.rightPaneSize,
          rightPaneMinimized: this.paneSizes.rightPaneMinimized,
          documentEditorPaneSize: effectiveMiddlePaneSize * newValue.ratio,
          figureEditorPaneSize: effectiveMiddlePaneSize * (1 - newValue.ratio),
          ratio: newValue.ratio
        }
        ApplicationModule.setPaneSizes(newPaneSizes);
      }
        break;
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES: {
        const effectiveMiddlePaneSize = this.calculateDocumentEditorPaneSize(this.paneSizes.leftPaneSize, this.paneSizes.leftPaneMinimized,
                                                                             this.paneSizes.rightPaneSize,
                                                                             this.paneSizes.rightPaneMinimized);
        const newPaneSizes: DocumentAndFigureSplitStateSizes = {
          mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES,
          leftPaneSize: this.paneSizes.leftPaneSize,
          leftPaneMinimized: this.paneSizes.leftPaneMinimized,
          rightPaneSize: this.paneSizes.rightPaneSize,
          rightPaneMinimized: this.paneSizes.rightPaneMinimized,
          documentEditorPaneSize: effectiveMiddlePaneSize * newValue.ratio,
          figureEditorPaneSize: effectiveMiddlePaneSize * (1 - newValue.ratio),
          ratio: newValue.ratio
        }
        ApplicationModule.setPaneSizes(newPaneSizes);
      }
    }
  }

  get DragMode() {
    return DragMode;
  }

  get EditReferenceSignMode() {
    return EditReferenceSignMode;
  }

  private get showDocumentOnly(): boolean {
    return this.applicationViewSplitState.mode === ApplicationViewSplitMode.DOCUMENT_ONLY;
  }

  private computePaneSizeConsideringMinimize(paneSize: number, minimized: boolean) {
    return minimized ? 0 : paneSize;
  }

  private get leftSplitPaneSizeComputed(): number {
    return this.computePaneSizeConsideringMinimize(this.paneSizes.leftPaneSize, this.paneSizes.leftPaneMinimized);
  }

  private get leftSplitPaneSizePxComputed(): number {
    return (this.leftSplitPaneSizeComputed / 100) * window.innerWidth;
  }

  private get documentEditorPaneSizeComputed(): number {
    return this.paneSizes.documentEditorPaneSize;
  }


  private get figureEditorPaneSizeComputed(): number {
    switch (this.paneSizes.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY:
        return 0;
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES:
        return this.paneSizes.figureEditorPaneSize;
      default:
        throw 'illegal application split mode';
    }
  }

  private get rightSplitPaneSizeComputed(): number {
    return this.computePaneSizeConsideringMinimize(this.paneSizes.rightPaneSize, this.paneSizes.rightPaneMinimized);
  }


  private onResize(event: { size: number }[]): void {

    // save initial size when resizing starts
    if(!this.panesResizing){
      this.initialPaneSizesOnResizing = { ...this.paneSizes };
      this.panesResizing = true;
    }
    this.resizePanes(event);
  }

  private updateRatio(newRatio: number) {
    const update = {userId: AuthModule.user!.id, ratio: newRatio};
    this.debounceSetDocumentAndFigureSplitState(update)?.catch(useDefaultErrorHandling);
  }

  private resizePanes(event: { size: number }[]) {
    switch (this.paneSizes.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY: {
        const leftPaneSize = event[0].size;
        const documentEditorSize = event[1].size;
        const rightPaneSize = event[2].size;

        const newSize: DocumentOnlySplitStateSizes = {
          mode: ApplicationViewSplitMode.DOCUMENT_ONLY,
          leftPaneSize: leftPaneSize,
          leftPaneMinimized: this.paneSizes.leftPaneMinimized && this.initialPaneSizesOnResizing!.leftPaneSize === leftPaneSize,
          rightPaneSize: rightPaneSize,
          rightPaneMinimized: this.paneSizes.rightPaneMinimized && this.initialPaneSizesOnResizing!.rightPaneSize === rightPaneSize,
          documentEditorPaneSize: documentEditorSize
        };

        ApplicationModule.setPaneSizes(newSize);
      }
        break;
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES: {
        const leftPaneSize = event[0].size;
        const documentEditorSize = event[1].size;
        const figureEditorSize = event[2].size;
        const rightPaneSize = event[3].size;
        const middlePaneSize = documentEditorSize + figureEditorSize;
        const newRatio = documentEditorSize / middlePaneSize;

        /*
         * Sometimes the splitter component can get into an invalid state when dragging the splitter into positions
         * that violates one of the splitters minimum contraints. This causes the events received from the components
         * to have values that dont add up to 100%, which breaks the calculation further down.
         * If we encounter that state we remain in the last valid one.
         */
        const sum = leftPaneSize + documentEditorSize + figureEditorSize + rightPaneSize;
        if (sum > 100) {
          return;
        }

        const newSize: DocumentAndFigureSplitStateSizes = {
          mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES,
          leftPaneSize: leftPaneSize,
          leftPaneMinimized: this.paneSizes.leftPaneMinimized && this.initialPaneSizesOnResizing!.leftPaneSize === leftPaneSize,
          rightPaneSize: rightPaneSize,
          rightPaneMinimized: this.paneSizes.rightPaneMinimized && this.initialPaneSizesOnResizing!.rightPaneSize === rightPaneSize,
          documentEditorPaneSize: documentEditorSize,
          figureEditorPaneSize: figureEditorSize,
          ratio: newRatio
        };

        ApplicationModule.setPaneSizes(newSize);
      }
        break;
      default:
        throw 'illegal application split mode';
    }
  }

  private onResized(event: { size: number }[]): void {
    this.resizePanes(event);

    if (this.paneSizes.mode === ApplicationViewSplitMode.DOCUMENT_AND_FIGURES
      && this.initialPaneSizesOnResizing?.mode === ApplicationViewSplitMode.DOCUMENT_AND_FIGURES
      && this.paneSizes.ratio !== this.initialPaneSizesOnResizing.ratio) {
      this.updateRatio(this.paneSizes.ratio);
    }

    this.panesResizing = false;
    this.initialPaneSizesOnResizing = null;
  }

  private onResizeLeft(event: { size: number }[]): void {
    this.searchPaneSize = event[1].size;
    this.searchPaneSizePx = (this.searchPaneSize / 100) * window.innerHeight;
  }

  private determineRightSplitterIndex(mode: ApplicationViewSplitMode): number {
    switch (mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY:
        return Splitters.rightWhenDocumentOnly;
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES:
        return Splitters.rightWhenDocumentAndFigures;
      default:
        throw 'illegal application split mode';
    }
  }

  private onSplitterClick(event: { index: number }): void {

    this.initialPaneSizesOnResizing = { ...this.paneSizes };
    this.panesResizing = true;

    if(event.index === Splitters.left){
      const newSize = this.collapseLeftPane(this.paneSizes);
      ApplicationModule.setPaneSizes(newSize);
    }

    const rightSplitterIndex = this.determineRightSplitterIndex(this.paneSizes.mode)
    if (event.index === rightSplitterIndex) {
      switch (this.paneSizes.mode) {
        case ApplicationViewSplitMode.DOCUMENT_ONLY: {
          const newSize = this.collapseRightPaneDocumentOnly(this.paneSizes);
          ApplicationModule.setPaneSizes(newSize);
        }
          break;
        case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES: {
          const newSize = this.collapseRightPaneDocumentAndFigures(this.paneSizes);
          ApplicationModule.setPaneSizes(newSize);
        }
          break;
        default:
          throw 'illegal application split mode';
      }
    }
  }

  private collapseLeftPane(paneSizes: PaneSizes): PaneSizes {
    const newSize: PaneSizes = {
      ...paneSizes,
    }
    newSize.leftPaneMinimized = !paneSizes.leftPaneMinimized;
    if (newSize.leftPaneMinimized) {
      newSize.documentEditorPaneSize = paneSizes.documentEditorPaneSize + paneSizes.leftPaneSize;
    } else {
      newSize.documentEditorPaneSize = paneSizes.documentEditorPaneSize - paneSizes.rightPaneSize;
    }
    return newSize;
  }

  private collapseRightPaneDocumentOnly(paneSizes: DocumentOnlySplitStateSizes): DocumentOnlySplitStateSizes {
    const newSize: DocumentOnlySplitStateSizes = {
      ...paneSizes,
    }

    newSize.rightPaneMinimized = !paneSizes.rightPaneMinimized;
    if (newSize.rightPaneMinimized) {
      newSize.documentEditorPaneSize = paneSizes.documentEditorPaneSize + paneSizes.rightPaneSize;
    } else {
      newSize.documentEditorPaneSize = paneSizes.documentEditorPaneSize - paneSizes.rightPaneSize;
    }

    return newSize;
  }

  private collapseRightPaneDocumentAndFigures(paneSizes: DocumentAndFigureSplitStateSizes): DocumentAndFigureSplitStateSizes {

    const newSize: DocumentAndFigureSplitStateSizes = {
      ...paneSizes,
    }

    newSize.rightPaneMinimized = !paneSizes.rightPaneMinimized;

    const deltaFigureEditorSize = newSize.rightPaneMinimized
      ? paneSizes.rightPaneSize
      : -paneSizes.rightPaneSize;

    const newFigureEditorSize = paneSizes.figureEditorPaneSize + deltaFigureEditorSize;
    const newRatio = paneSizes.documentEditorPaneSize / (paneSizes.documentEditorPaneSize + newFigureEditorSize);

    newSize.figureEditorPaneSize = newFigureEditorSize;
    newSize.ratio = newRatio;

    return newSize;
  }

  private onSplitterHorizontalClick(event: { index: number }): void {
    // If we double click the splitter in Safari it may select some areas unintentionally, so we clear the selection
    this.clearSelection();
  }

  private clearSelection(): void {
    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
  }

  // Determine if search search panel should show a scroll bar
  private scrollSearch(): boolean {
    let minWidth = 299;

    // If there is a vertical scroll bar we need more space
    if (this.searchPane && this.searchPaneSizePx && this.searchPane.$el.scrollHeight > this.searchPane.$el.clientHeight) {
      // Hack to refresh on vertical pane resize: Checking searchPaneSizePx
      minWidth += this.scrollbarSize;
    }

    const searchScrollActiveNext = this.leftSplitPaneSizePxComputed < minWidth;

    // Reset scroll position on scroll bar deactivation, else the pane may freeze centered
    if (this.searchPane && this.searchScrollActive && !searchScrollActiveNext) {
      this.searchPane.$el.scrollLeft = 0;
    }
    this.searchScrollActive = searchScrollActiveNext;
    return this.searchScrollActive;
  }

  get figures(): ApplicationFigureThumbnail[] {
    return ApplicationFigureModule.applicationFigures;
  }

  @Watch('figures', {deep: true})
  private onFiguresChange(newValue: ApplicationFigureThumbnail[], oldValue: ApplicationFigureThumbnail[]): void {
    this.initialFigureGuid = selectNewFigure(this.initialFigureGuid, newValue, oldValue);
    this.actualFigureGuid = selectNewFigure(this.actualFigureGuid, newValue, oldValue);
  }

  mounted(): void {

    // sync panes with store
    this.layoutPanes(ApplicationModule.applicationViewSplitState);

    if (this.applicationGuid) {
      // If we have a guid - load the application document in the store

      const fetchApplicationDocumentPromise = ApplicationModule.fetchApplicationDocument(this.applicationGuid);
      const fetchFiguresPromise = ApplicationFigureModule.fetchApplicationFiguresForApplicationDocument(this.applicationGuid);
      Promise.all([fetchApplicationDocumentPromise, fetchFiguresPromise])
        .then((values) => {
          const thumbnails = values[1];
          this.initialFigureGuid = selectNewFigure(null, thumbnails, []);
        })
        .catch(useDefaultErrorHandling);
    }
  }

  get applicationGUID(): string {
    return this.applicationGuid;
  }

  get actualFigureGUID(): string | null {
    return this.actualFigureGuid;
  }

  beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: (success?: boolean) => void) {

    // Before leaving the route, try to save possible changes.
    // If an error occurs, show the error and DO NOT change route.
    this.applicationEditorInstance?.saveChanges(false)
      .then(() => setTimeout(() => next(), 100))
      .catch(() => next(false));
  }
}

export default toNative(Application);
</script>

<style lang="scss">
.application {
  .application-main-splitpanes {
    > div:nth-child(2) {
      border-left: none !important;
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}
</style>

<style lang="scss" scoped>

.application {
  width: 100%;
  height: 100%;

  .document-structure-tree-pane {
    justify-content: normal;
    align-items: flex-start;
  }

  .splitpanes__pane {
    flex-direction: column;
  }

  .search-pane {
    justify-content: normal;
    display: block;
    overflow-x: hidden;
  }
}

.scrollable {
  overflow: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
