<template>

  <div ref="applicationFigureListRef" class="application-figure-list">
    <form ref="imageUploadForm">
      <input
        class="file-picker-input"
        ref="imageUpload"
        type='file'
        accept='.jpg, .jpeg, .png, .svg'
        enctype="multipart/form-data"
        @change="onFilePicked"/>
    </form>

    <div class="application-figure-header" ref="applicationFigureHeaderRef">
      <h2>{{ $t('figures') }} ({{ applicationFigureList.length }})</h2>
      <div class="header-buttongroup">
        <!--        <button class="icon-button search-button" :title="$t('searchApplicationFigures')">-->
        <!--          <i class="exi exi-search"></i>-->
        <!--        </button>-->
        <b-dropdown
          v-if="!isLoading"
          :mobile-modal="false"
          position="is-bottom-left"
          append-to-body
          aria-role="menu"
          trap-focus>
          <template #trigger="{}">
            <button class="icon-button add-button"
                    :title="$t('createNewFigure')">
              <i class="exi exi-plus"/>
            </button>
          </template>
          <b-dropdown-item aria-role="listitem" :title="$t('newFigure.portrait')" @click="clickAddFigure(FigureOrientation.PORTRAIT)">
            <div class="media">
              <i class="exi exi-portrait media-left"/>
              <div class="media-content">
                {{
                  $t('orientation.portrait')
                }}
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :title="$t('newFigure.landscape')" @click="clickAddFigure(FigureOrientation.LANDSCAPE)">
            <div class="media">
              <i class="exi exi-landscape media-left"/>
              <div class="media-content">
                {{
                  $t('orientation.landscape')
                }}
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <button v-if="isLoading"
                :title="$t('general.loading')"
                class="icon-button loading-button">
          <i class="exi exi-small-spinner-unmasked rotating"/>
        </button>
      </div>
    </div>

    <ConfirmationDialog ref="deleteApplicationFigureDialog" titleKey="deleteApplicationFigure.title"
                        questionKey="deleteApplicationFigure.question"/>

    <ApplicationFigurePreviewDialog ref="applicationFigurePreviewDialog"/>

    <div ref="tableContainer" class="table-container">
      <b-table
        :data="applicationFigureListForDrag"
        :show-header="false"
        hoverable
        :draggable="draggable"
        narrowed
        :mobile-cards="false"
        @drop="drop"
        @dragstart="dragstart"
        @dragover="dragover"
        @dragleave="dragleave">

        <b-table-column field="figure" :label="$t('figure')" v-slot="props" header-class="table-header">
          <div class="figure-image" @dblclick="openEditor(props.row.guid)">
            <b-skeleton v-if="!props.row.thumbnail" square width="120px" height="120px"></b-skeleton>
            <img class="figure-thumbnail" v-if="props.row.thumbnail"
                 :src="props.row.thumbnail"
                 :title="$t('figure') + ' ' + (props.row.position + 1)"
                 :alt="$t('figure') + ' ' + (props.row.position + 1)">
            <div>
              <b-button class="figure-button-top-left" :title="$t('editFigure')" @click="openEditor(props.row.guid)">
              <span class="icon is-small">
                <i class="exi exi-pen"/>
              </span>
              </b-button>
              <b-button class="figure-button-top-right" :title="$t('showFigure')" @click="openPreview(props.row)">
              <span class="icon is-small">
                <i class="exi exi-view"/>
              </span>
              </b-button>
              <b-button class="figure-button-bottom-left" :title="$t('uploadFigure')"
                        @click="clickChangeBaseImage(props.row)">
              <span class="icon is-small">
                <i class="exi exi-upload"/>
              </span>
              </b-button>
              <!--              <b-button class="figure-button-bottom-right" :title="$t('downloadFigure')">-->
              <!--              <span class="icon is-small">-->
              <!--                <i class="exi exi-download"></i>-->
              <!--              </span>-->
              <!--              </b-button>-->
            </div>
          </div>
        </b-table-column>

        <b-table-column field="description" :label="$t('description')" v-slot="props" header-class="table-header">
          <div class="figure-list-item" @dblclick="props.row.guid !== currentGuid ? openEditor(props.row.guid) : (() => {})">
            <div class="figure-list-item-header">
              <h3>{{ $t('figure.abbreviated') }} {{ props.row.position + 1 }}</h3>
              <div class="button-container" v-if="props.row.guid !== currentGuid">
                <b-button class="hover-button" :title="$t('general.edit')" @click="clickEdit(props.row.guid)">
                <span class="icon is-small">
                  <i class="exi exi-pen"/>
                </span>
                </b-button>
                <b-button class="hover-button button-critical"
                          v-if="!props.row.isMainFigure || applicationFigureList.length === 1"
                          @click="clickDelete(props.row)" :title="$t('general.delete')">
                <span class="icon is-small">
                  <i class="exi exi-delete"/>
                </span>
                </b-button>
                <b-button v-if="props.row.isMainFigure" class="indicator-favorite" :title="$t('currentMainFigure')">
                <span class="icon is-small">
                  <i class="exi exi-favorite-filled"/>
                </span>
                </b-button>
                <b-button v-else class="hover-button main-figure-button" @click="setAsMainFigure(props.row)"
                          :title="$t('setAsMainFigure')">
                <span class="icon is-small">
                  <i class="exi exi-favorite-empty"/>
                  <i class="exi exi-favorite-filled"/>
                </span>
                </b-button>
              </div>
            </div>

            <div class="figure-list-item-description">
                <textarea :ref="'description'"
                          v-if="props.row.guid === currentGuid"
                          v-on:blur="saveAndCloseEditMode(props.row, $event)"
                          @focusin="onDescriptionFocusIn"
                          rows="3"
                          v-model="props.row.description" v-on:keydown.enter="saveAndCloseEditMode(props.row, $event)"
                          :maxlength="500" :has-counter="false">
                </textarea>
              <div v-else class="description">
                <div v-if="isDescriptionEmpty(props.row.description)" class="no-content">
                  {{ $t('noDescription') }}
                </div>
                <div v-else :title="props.row.description">
                  {{ props.row.description }}
                </div>
              </div>
            </div>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Ref, toNative, Vue} from 'vue-facing-decorator';
import ApplicationFigureDialog from '@/components/ApplicationFigureDialog.vue';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {BuefyDragPayload} from '@/components/common/buefy.model';
import ApplicationFigureModule from '@/store/modules/ApplicationFigureModule';
import {ApplicationFigureListEntry, ImageUploadType} from '@/store/models/applicationFigure.model';
import {FigureOrientation} from '../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import ApplicationFigurePreviewDialog, {
  ApplicationFigurePreviewDialog as ApplicationFigurePreviewDialogClass
} from '@/components/ApplicationFigurePreviewDialog.vue';
import {ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';
import {ErrorWrapper} from '@/util/error.wrapper';
import {LocalizedMessageKey} from '@/api/models/exception.model';
import {useDefaultErrorHandling} from '@/errorHandling';

export type ApplicationFigureListEvents = 'ready';

export type ApplicationFigureListReadyEvent = {
  headerHeightPx: number;
}

export type EditFigureCallback = (figureGuid: string) => void;

@Component(
  {
    emits: [
      'ready'
    ],
    components: {
      ApplicationFigureDialog,
      ConfirmationDialog,
      ApplicationFigurePreviewDialog
    }
  })
class ApplicationFigureList extends Vue {
  @Ref('applicationFigureHeaderRef') private applicationFigureHeaderRef!: HTMLElement;
  @Ref('applicationFigureListRef') private applicationFigureListRef!: HTMLElement;
  @Ref('table') private table!: never; // Buefy Table
  @Ref('deleteApplicationFigureDialog') private deleteApplicationFigureDialog!: ConfirmationDialogClass;
  @Ref('applicationFigurePreviewDialog') private applicationFigurePreviewDialog!: ApplicationFigurePreviewDialogClass;
  @Ref('imageUploadForm') private fileUploaderForm!: HTMLFormElement;
  @Ref('imageUpload') private fileUploader!: HTMLInputElement;
  @Ref('tableContainer') private tableContainer!: HTMLElement;

  @Prop({required: true})
  private applicationGuid!: string;

  @Prop({required: true})
  private figures!: ApplicationFigureThumbnail[];

  @Prop({required: true})
  private onEditFigureClicked!: EditFigureCallback;

  private orientation = FigureOrientation.LANDSCAPE;
  private imageUpload = ImageUploadType.NEW_FIGURE;
  private applicationFigureToBeUpdated!: ApplicationFigureThumbnail;
  private currentGuid = '';
  private currentDescription = "";
  private draggedFigure!: BuefyDragPayload;
  private draggable = true;
  private fileSiteError = new ErrorWrapper(LocalizedMessageKey.PAYLOAD_TOO_LARGE_FIGURE_RENDERING_ERROR);

  mounted() {
    const readyEvent: ApplicationFigureListReadyEvent = {
      headerHeightPx: this.headerHeightPx()
    }
    this.emitReady(readyEvent);
  }

  @Emit('ready')
  emitReady(event: ApplicationFigureListReadyEvent) {
    return event;
  }

  get applicationFigureListEntries(): ApplicationFigureListEntry[] {

    return this.figures.map((applicationFigure: ApplicationFigureThumbnail, index: number): ApplicationFigureListEntry => ({
      ...applicationFigure,
      edit: false,
      position: index,
      thumbnail: `data:image/png;base64,${applicationFigure.thumbnail}`,
    }));
  }

  get FigureOrientation() {
    return FigureOrientation;
  }

  get applicationFigureList(): ApplicationFigureListEntry[] {
    return this.applicationFigureListEntries;
  }

  // Special getter for the table to add an invisible extra entry to make it possible to drag items to the last position
  // Will only be added if there already are at least two elements
  private get applicationFigureListForDrag(): ApplicationFigureListEntry[] {
    const figures = this.applicationFigureListEntries;
    return (figures.length < 2) ? figures : figures.concat(
      [
        {
          // Add empty element for DnD hack
          guid: '',
          position: figures.length,
          description: '',
          isMainFigure: false,
          thumbnail: '',
          edit: false
        }
      ]);
  }

  get isLoading(): boolean {
    return ApplicationFigureModule.isApplicationFiguresLoading;
  }

  private isDescriptionEmpty(description: string): boolean {
    return description == null || description == '';
  }

  headerHeightPx() {
    return this.applicationFigureHeaderRef.offsetHeight;
  }

  private clickAddFigure(orientation: FigureOrientation) {
    this.orientation = orientation;
    this.imageUpload = ImageUploadType.NEW_FIGURE;
    this.openFileDialog();
  }

  private async clickChangeBaseImage(applicationFigure: ApplicationFigureThumbnail) {
    this.imageUpload = ImageUploadType.CHANGE_BASE_IMAGE;
    this.applicationFigureToBeUpdated = applicationFigure;
    await this.openFileDialog();
  }


  private async openFileDialog(): Promise<void> {
    this.fileUploader.click();
  }

  private async onFilePicked(event: Event): Promise<FormData | null> {
    const formData = new FormData();
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;

    // If the user has selected a file
    if (files !== null) {
      const selectedFile: File = (target.files as FileList)[0];
      formData.append('file', selectedFile);

      // Reset everything asynchronously so it also will be called in case of an exception
      if (this.fileUploader) {
        setTimeout(() => {
          this.fileUploader.value = "";
          this.fileUploader.files = null;
          this.fileUploaderForm.reset();
        }, 200);
      }

      // Check file size. Must be <= 3MB (= 3 * 1024 * 1024 Byte)
      if (selectedFile.size > 3145728) {
        this.fileSiteError.throwError();
        return null;
      }

      switch (this.imageUpload) {
        case ImageUploadType.NEW_FIGURE:
          await this.createFigure(formData);
          break;
        case ImageUploadType.CHANGE_BASE_IMAGE:
          await this.updateFigure(formData);
          break;
      }
    }
    return formData;
  }

  private async updateFigure(formData: FormData): Promise<void> {
    formData.append('guid', this.applicationFigureToBeUpdated.guid as string);
    formData.append('position', this.applicationFigureToBeUpdated.position.toString());
    formData.append('isMainFigure', this.applicationFigureToBeUpdated.isMainFigure.toString());
    await ApplicationFigureModule.updateApplicationFigure(formData).catch(useDefaultErrorHandling);
  }

  private async createFigure(formData: FormData): Promise<void> {
    formData.append('applicationDocument', this.applicationGuid);
    formData.append('orientation', this.orientation);
    await ApplicationFigureModule.createApplicationFigure(formData).catch(useDefaultErrorHandling);

    this.scrollToBottom();
  }

  private setAsMainFigure(applicationFigure: ApplicationFigureThumbnail): void {
    ApplicationFigureModule.setMainFigure(applicationFigure).catch(useDefaultErrorHandling);
  }

  private clickEdit(guid: string): void {
    this.applicationFigureList.forEach((item: ApplicationFigureListEntry) => {
      item.edit = (item.guid === guid);
      if (item.edit) {
        // Editing for this applicationFigure was activated so remember the original name
        this.currentGuid = guid;
        this.currentDescription = item?.description || '';
      }
    });
    this.setFocusDelayed(guid); // The delay is needed to make sure the lazy $refs are present
  }

  private onDescriptionFocusIn(): void {
    // Disables the drag and drop when the description text area is focused
    this.draggable = false;
  }

  private async saveAndCloseEditMode(applicationFigure: ApplicationFigureThumbnail, event: FocusEvent | KeyboardEvent): Promise<void> {
    event.preventDefault();

    // When the edit mode is finished, the elements in the list are draggable again
    this.draggable = true;
    const newDescription: string = (event.target as HTMLInputElement).value;
    const applicationFigureThumbnail = await ApplicationFigureModule.updateApplicationFigureDescription(
      {
        guid: applicationFigure.guid,
        description: newDescription,
        isMainFigure: applicationFigure.isMainFigure,
        position: applicationFigure.position
      }).catch(useDefaultErrorHandling);

    if (applicationFigureThumbnail) {
      applicationFigure.description = applicationFigureThumbnail.description?.replace(/(\r\n|\n|\r)/gm, "");
      this.closeEditMode(applicationFigure.guid as string);
      return ApplicationFigureModule.updateFigureListOnDescriptionUpdate(applicationFigure);
    }
  }

  private closeEditMode(guid: string): void {
    this.currentGuid = '';
    this.applicationFigureList.forEach((item: ApplicationFigureListEntry) => {
      if (item.guid === guid) {
        item.edit = false;
      }
    });
  }

  private openEditor(figureGuid: string): void {
    this.onEditFigureClicked(figureGuid);
  }

  private clickDelete(applicationFigure: ApplicationFigureListEntry): void {
    this.deleteApplicationFigureDialog.open(
      {
        titleKey: 'deleteApplicationFigure.title',
        questionKey: 'deleteApplicationFigure.question',
        questionValues: [(applicationFigure.position + 1).toString()],
        options: [
          {
            labelKey: 'general.delete',
            class: 'button-delete',
            callback: async () => {
              await ApplicationFigureModule.deleteApplicationFigure(applicationFigure.guid as string)
                .catch(useDefaultErrorHandling);
            },
            autofocus: true
          }, {
            labelKey: 'general.cancel',
            class: 'button-cancel'
          }]
      });
  }

  private async updatePosition(applicationFigure: ApplicationFigureListEntry, position: number): Promise<void> {
    const formData = new FormData();
    formData.append('guid', applicationFigure.guid as string);
    formData.append('position', position.toString());
    formData.append('isMainFigure', applicationFigure.isMainFigure.toString());

    await ApplicationFigureModule.updateApplicationFigure(formData).catch(useDefaultErrorHandling);
  }

  dragstart(payload: BuefyDragPayload) {
    // Prevent dragging while in edit mode and for the last element that is part of the hack for dragging to the last position
    if (!payload.row.edit && this.applicationFigureList.length > 1 && payload.index < this.applicationFigureList.length) {
      if (payload.event.dataTransfer) {
        payload.event.target.closest('tr').classList.add('selected-figure');
        payload.event.dataTransfer.dropEffect = 'move';
        this.draggedFigure = payload;
      }
    } else {
      payload.event.preventDefault();
    }
  }

  dragover(payload: BuefyDragPayload) {
    if (payload.event.dataTransfer) {
      this.addSelectionMarker(payload);
      payload.event.dataTransfer.dropEffect = 'move';
      payload.event.preventDefault();
    }
  }

  drop(payload: BuefyDragPayload) {
    if (payload.event.dataTransfer) {
      if ((payload.row.position - this.draggedFigure.row.position) != 1) {
        this.updatePosition(this.draggedFigure.row, payload.row.position);
      }
      this.draggedFigure.event.target.closest('tr').classList.remove('selected-figure');
      this.removeSelectionMarker(payload);
    }
  }

  dragleave(payload: BuefyDragPayload) {
    if (payload.event.dataTransfer) {
      this.removeSelectionMarker(payload);
      payload.event.dataTransfer.dropEffect = 'move';
      payload.event.preventDefault();
    }
  }

  removeSelectionMarker(payload: BuefyDragPayload) {
    if (typeof payload.event.target.closest === 'function') {
      payload.event.target.closest('tr').classList.remove('destination-position');
    }
  }

  addSelectionMarker(payload: BuefyDragPayload) {
    if (typeof payload.event.target.closest === 'function') {
      if (!payload.event.target.closest('tr').classList.contains('destination-position')) {
        payload.event.target.closest('tr').classList.add('destination-position');
      }
    }
  }

  /**
   * Sets the focus to the text area of the given Application Figure Sign (via guid) delayed by 10ms.
   * The delay is needed to make sure the lazy $refs are present.
   * @param guid guid of the row to jump to
   * @param inputType Default is 'description'
   * @param delay Default is 10ms
   */
  private setFocusDelayed(guid: string, inputType?: string, delay?: number): void {
    setTimeout(() => {
      (this.$refs[(inputType ? inputType : 'description')] as HTMLElement).focus();
    }, delay ? delay : 10);
  }

  private openPreview(applicationFigure: ApplicationFigureListEntry): void {
    this.applicationFigurePreviewDialog.open(applicationFigure.guid as string);
  }

  private scrollToBottom(): void {
    // Scrolls down to the bottom of the list
    this.tableContainer.scrollTop = this.tableContainer.scrollHeight;
  }
}

export default toNative(ApplicationFigureList);
</script>
<style lang="scss">
@import 'src/assets/styles/constants';

.application-figure-list {
  width: inherit;
  height: 100%;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;

  .header-button {
    display: flex;
    float: right;
  }


  .application-figure-header {
    width: inherit;
    height: $subheader-height;
    min-height: $subheader-height;
    padding-left: 5px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;

    -webkit-user-select: none;
    user-select: none;
    border-bottom: 1px solid $pengine-grey;

    h2 {
      flex-shrink: 0;
      margin-top: 0;
      padding-left: 12px;
      margin-bottom: 0px;
    }

    .header-buttongroup {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .icon-button {
        display: flex;
      }

      .add-button {
        padding-left: 12px;
        padding-right: 12px;
      }

      .loading-button {
        padding-left: 12px;
        padding-right: 12px;
      }

      .search-button {
        padding-left: 12px;
        padding-right: 0px;
      }
    }
  }

  .destination-position {
    td {
      box-shadow: inset 0px 10px 0px -7px $pengine-orange !important;
    }
  }

  .selected-figure {
    td {
      background-color: $pengine-orange-light-light !important;
    }
  }

  .table-container {
    overflow-y: auto;
    height: calc(100% - 50px);

    .table {
      border: transparent !important;
      height: 100%;

      tr {
        background-color: transparent;
        min-height: $figure-size;
        height: $figure-size;

        td {
          height: 100%;
          padding: 0px;
        }

        td:first-child {
          width: $figure-size;
        }

        td:last-child {
          min-width: 140px;
          max-width: 200px;
        }

        // The last entry is just a hack to make it possible to drag to the end of the list
        &:not(:first-child):last-child {
          height: 4px;

          .figure-image, .figure-list-item {
            display: none;
          }
        }
      }

      tr:not(.is-empty):hover {
        background-color: $pengine-grey-light-light !important;
      }
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants';

// Variables
$icon-positioning: 20%;

.figure-list-item {
  width: inherit;
  height: calc(#{$figure-size} + 12px);
  padding: 6px 6px 6px 0;

  .figure-list-item-header {
    width: inherit;
    min-height: $figure-list-header-height;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: $font-size-figure-list-header;
    }
  }

  .figure-list-item-description {

    textarea {
      width: 100%;
      min-height: calc(#{$figure-size} - #{$figure-list-header-height} - 1px);
      margin-top: 1px;

      resize: none;
      background-color: white;
      line-height: 1.3em;
      font-size: $font-size-small;
    }

    .description {
      color: $text-color;

      div {
        margin-top: 3px;
        width: inherit;
        line-height: 1.3em;
        font-size: $font-size-small;

        overflow: hidden;
        word-wrap: break-word;

        text-align: start;
        //noinspection CssUnknownProperty
        display: -webkit-box;
        //noinspection CssUnknownProperty
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .no-content {
        color: $pengine-grey-dark;
      }
    }
  }
}

.figure-image {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: calc(#{$figure-size} + 12px);
  height: calc(#{$figure-size} + 12px);

  overflow: hidden;
}

.figure-thumbnail {
  width: $figure-size;
  height: $figure-size;
  object-fit: cover;
  background-color: white;
}

.figure-image-button {
  @extend .hover-button;
  visibility: hidden;
  background-color: transparent;
  position: absolute;

  border: none;
  outline: none;
  transition: none;
}

.figure-image:hover .figure-image-button {
  visibility: visible;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
}

.figure-button-top-left {
  @extend .figure-image-button;
  top: $icon-positioning;
  left: $icon-positioning;
}

.figure-button-top-right {
  @extend .figure-image-button;
  top: $icon-positioning;
  left: (97%-$icon-positioning);
}

.figure-button-bottom-left {
  @extend .figure-image-button;
  top: (103%-$icon-positioning);
  left: $icon-positioning;
}

.figure-button-bottom-right {
  @extend .figure-image-button;
  top: (97%-$icon-positioning);
  left: (97%-$icon-positioning);
}


.figure-list-item:hover .hover-button {
  visibility: visible !important;
}

.button-critical:hover, .button-critical:focus {
  .exi {
    background-color: $pengine-delete-red !important;
  }
}

.main-figure-button {
  .exi-favorite-filled {
    display: none;
  }

  .exi-favorite-empty {
    display: inherit;
  }
}

.main-figure-button:hover, .main-figure-button:focus {
  .exi-favorite-filled {
    display: inherit;
  }

  .exi-favorite-empty {
    display: none;
  }
}


.application-figure-list {

  .file-picker-input {
    display: none;
  }

  .table-container {
    font-size: $font-size-normal;


    .button-container {
      height: $figure-list-header-height;
      float: right;

      .hover-button {
        visibility: hidden;
        transition: none;
      }

      .hover-button:active {
        outline: none;
        box-shadow: none;
      }

      .button {
        height: $figure-list-header-height;
        background-color: transparent;
        border: none;
        padding: 0px 10px 0px 10px;
      }
    }
  }
}
</style>
