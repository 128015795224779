import {
  CompassDirection,
  FigureOrientation,
  FigureSymbolType,
  MarkerSnapPoint,
  OFFSET,
  ReferenceSignMarker,
  RESOLUTION,
  SIZES,
  SymbolSnapDirection,
  SymbolSnapPoint
} from '../api/models/drawingbase.model';
import * as paper from 'paper';
import * as uuid from 'uuid';

/**
 * Converts the given number centimiters to pixels.
 *
 * @example
 * dpi = 300 px / in
 * 1 inch = 2.54 cm
 * 300 dpi = 300 px / 2.54 cm
 *
 * @see https://github.com/ryanve/res
 * @param cm The number of centimiters to be converted.
 * @returns {number}
 */
const cmToPixels = (cm: number): number => {
  const dpi = RESOLUTION;
  return dpi * cm / 2.54;
}

/**
 * Gets the offset of the canvas.
 * @returns {number}
 */
export const getOffset = (): number => {
  return cmToPixels(OFFSET);
}

/**
 * Gets the corresponding canvas size based on the given orientation.
 * @param orientation The orientation of the figure to be used to determine the canvas size.
 */
export const getCanvasSizeFromOrientation = (orientation: FigureOrientation): { width: number; height: number } => {
  return SIZES.A4[orientation]
}

/**
 * Throws an error, if obj is undefined or null.
 * @param obj
 */
export function required<T>(obj: T | undefined | null): T {
  if (obj === undefined || obj === null) {
    throw new Error("Object is required");
  }
  return obj;
}

export function asGroup(item: paper.Item | undefined): paper.Group | undefined {
  return item && item.className === 'Group' ? item as paper.Group : undefined;
}

export function asGroupRequired(item: paper.Item): paper.Group {
  return required(asGroup(item));
}

export function filterOnlyGroups(items: paper.Item[]): paper.Group[] {
  return items
    .map(it => asGroup(it))
    .filter(it => !!it)
    .map(it => required(it));
}

export function getSymbolType(item: paper.Item | undefined): FigureSymbolType | undefined {
  return asGroup(item)?.data.type;
}

function isType(item: paper.Item, type: FigureSymbolType): boolean {
  return getSymbolType(item) === type;
}

export type ItemPredicate = (item: paper.Item) => boolean;

export function isTypeLine(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.LINE);
}

export function isTypeArrow(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.ARROW);
}

export function isTypeBrace(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.BRACE);
}

export function isTypeCurve(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.CURVE);
}

export function isTypePaletteSymbol(item: paper.Item): boolean {
  return isTypeLine(item) || isTypeArrow(item) || isTypeBrace(item) || isTypeCurve(item);
}

export function isTypeReferenceSignMarker(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.REFERENCE_SIGN_MARKER);
}

export function isTypeHelpLine(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.HELP_LINE);
}

export function isTypeWithUnderlineSupport(item: paper.Item): boolean {
  return isTypeReferenceSignMarker(item);
}

export function isTypeWithSnapPoints(item: paper.Item): boolean {
  return isTypeLine(item)
    || isTypeArrow(item)
    || isTypeBrace(item)
    || isTypeReferenceSignMarker(item);
}

export function getSymbolGuid(item: paper.Item | undefined): string | undefined {
  return asGroup(item)?.name;
}

export function getSymbolGuidRequired(item: paper.Item | undefined): string {
  return required(getSymbolGuid(item));
}

/**
 * Compare two strings using localeCompare() with numeric sorting and base sensitivity.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 */
export function compareStrings(a: string, b: string): number {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
}

/**
 * Creates a valid guid with uuid v4 format.
 * @private
 */
export function createGuid() {
  return uuid.v4();
}

export function getBraceTip(item: paper.Item) {
  const braceTips = item.children
    .filter(it => it instanceof paper.Group)
    .flatMap(it => it.children)
    .flatMap(it => it.children)
    .filter(it => it.data.type === "BraceTip")

  return (braceTips[0] as paper.Path).segments[1];
}

export function isSnapCloud(item: paper.Item) {
  return item.data.type === "snapPointObject";
}

export function isSnapPoint(item: paper.Item) {
  return item.data.type === "SnapPoint";
}

export function getSnapCloud(item: paper.Item) {
  const snapCloud = item.children.find(it => isSnapCloud(it));

  if (!snapCloud) {
    const itemName = item.name || 'Unnamed item';
    throw new Error(`Snap cloud not found: The item "${itemName}" does not contain a child with type "snapPointObject".`);
  }
  return snapCloud;
}

export function getMarkerSnapPoint(item: paper.Item,
                                   direction: CompassDirection) {
  return item.data.snapPoints
    .find((it: MarkerSnapPoint) => it.direction === direction);
}

export function getSymbolSnapPoint(item: paper.Item,
                                   direction: SymbolSnapDirection) {
  return item.data.snapPoints
    .find((it: SymbolSnapPoint) => it.direction === direction);
}

export function initSnapPoints(item: paper.Item) {
  if (isTypeReferenceSignMarker(item)) {
    const snapCloud = getSnapCloud(item);

    item.data.snapPoints = snapCloud!.children
      .map((it: paper.Item) => {
        return {
          guid: createGuid(),
          coordinateX: it.position.x,
          coordinateY: it.position.y,
          direction: it.data.direction,
          symbolSnapPointGuids: []
        };
      });
  } else if (isTypeLine(item) || isTypeArrow(item)) {
    const line = item.children[0] as paper.Path.Line;
    const startSegment = line.segments[0];
    const endSegment = line.segments[1];

    item.data.snapPoints = [
      {
        guid: createGuid(),
        coordinateX: startSegment.point.x,
        coordinateY: startSegment.point.y,
        direction: SymbolSnapDirection.X1,
        markerSnapPointGuid: null
      },
      {
        guid: createGuid(),
        coordinateX: endSegment.point.x,
        coordinateY: endSegment.point.y,
        direction: SymbolSnapDirection.X2,
        markerSnapPointGuid: null
      }];
  } else if (isTypeBrace(item)) {
    const braceTip = getBraceTip(item);

    item.data.snapPoints = [
      {
        guid: createGuid(),
        coordinateX: braceTip.point.x,
        coordinateY: braceTip.point.y,
        direction: SymbolSnapDirection.MIDPOINT_BRACE,
        markerSnapPointGuid: null
      }];
  } else {
    throw new Error("");
  }
}

export function updateSnapPoints(item: paper.Item) {
  if (isTypeReferenceSignMarker(item)) {
    const snapCloud = getSnapCloud(item);

    item.data.snapPoints = item.data.snapPoints
      .map((it: MarkerSnapPoint) => {
        const snapPoint = snapCloud!.children.find(snapPoint => snapPoint.data.direction === it.direction);
        return {
          ...it,
          coordinateX: snapPoint!.position.x,
          coordinateY: snapPoint!.position.y
        };
      });
  } else if (isTypeLine(item) || isTypeArrow(item)) {
    const line = item.children[0] as paper.Path.Line;
    const startSegment = line.segments[0];
    const endSegment = line.segments[1];

    item.data.snapPoints = item.data.snapPoints
      .map((it: SymbolSnapPoint) => ({
        ...it,
        coordinateX: it.direction === SymbolSnapDirection.X1 ? startSegment.point.x : endSegment.point.x,
        coordinateY: it.direction === SymbolSnapDirection.X1 ? startSegment.point.y : endSegment.point.y
      }));
  } else if (isTypeBrace(item)) {
    const braceTip = getBraceTip(item);

    item.data.snapPoints = item.data.snapPoints
      .map((it: SymbolSnapPoint) => ({
        ...it,
        coordinateX: braceTip.point.x,
        coordinateY: braceTip.point.y,
      }))
  } else {
    throw new Error("");
  }
}

/**
 *
 * @param markerItem
 * @param markerSnapPointDirection
 * @param symbolItem
 * @param symbolSnapPointDirection
 * @private
 */
export function connectSnapPoints(markerItem: paper.Item,
                                  markerSnapPointDirection: CompassDirection,
                                  symbolItem: paper.Item,
                                  symbolSnapPointDirection: SymbolSnapDirection) {
  const markerSnapPoint = getMarkerSnapPoint(markerItem, markerSnapPointDirection);
  const symbolSnapPoint = getSymbolSnapPoint(symbolItem, symbolSnapPointDirection);

  symbolSnapPoint.markerSnapPointGuid = markerSnapPoint.guid;

  if (!markerSnapPoint.symbolSnapPointGuids.includes(symbolSnapPoint.guid)) {
    markerSnapPoint.symbolSnapPointGuids.push(symbolSnapPoint.guid);
  }
}

/**
 *
 * @param markerItem
 * @param markerSnapPointDirection
 * @param symbolItem
 * @param symbolSnapPointDirection
 * @private
 */
export function detachSnapPoints(markerItem: paper.Item,
                                 markerSnapPointDirection: CompassDirection,
                                 symbolItem: paper.Item,
                                 symbolSnapPointDirection: SymbolSnapDirection) {
  const markerSnapPoint = getMarkerSnapPoint(markerItem, markerSnapPointDirection);
  const symbolSnapPoint = getSymbolSnapPoint(symbolItem, symbolSnapPointDirection);

  if (symbolSnapPoint.markerSnapPointGuid === markerSnapPoint.guid) {
    symbolSnapPoint.markerSnapPointGuid = null;
  }
  if (markerSnapPoint.symbolSnapPointGuids.includes(symbolSnapPoint.guid)) {
    markerSnapPoint.symbolSnapPointGuids = markerSnapPoint.symbolSnapPointGuids
      .filter((it: string) => it !== symbolSnapPoint.guid);
  }
}

/**
 *
 * @param markerItem
 * @param symbolItem
 */
export function detachAllSnapPoints(markerItem: paper.Item,
                                    symbolItem: paper.Item) {
  markerItem.data.snapPoints.forEach((markerSnapPoint: MarkerSnapPoint) => {
    symbolItem.data.snapPoints.forEach((symbolSnapPoint: SymbolSnapPoint) => {
      detachSnapPoints(markerItem, markerSnapPoint.direction, symbolItem, symbolSnapPoint.direction);
    });
  });
}

export interface SnapPointCandidate {
  distance: number;
  child: paper.Item;
  snapPoint: paper.Item;
  alignmentPoint: paper.Point;
  alignmentDirection: SymbolSnapDirection;
}

export interface DockLineCandidate {
  distance: number;
  child: paper.Item;
}

export type SnapCandidate = SnapPointCandidate | DockLineCandidate;

export function findSnapCandidates(markerItem: paper.Item,
                                   segment: paper.Segment,
                                   alignmentDirection: SymbolSnapDirection,
                                   child: paper.Item): SnapPointCandidate[] {
  return markerItem.children
    .filter(it => isSnapCloud(it))
    .flatMap(it => it.children)
    .filter(it => isSnapPoint(it))
    .map(it => ({
      distance: it.position.getDistance(segment.point),
      child: child,
      snapPoint: it,
      alignmentPoint: segment.point,
      alignmentDirection: alignmentDirection
    }));
}

export function highlightSnapPoint(referenceGroup: paper.Item, snapPoint: paper.Item, zoom: number) {
  const center = snapPoint.position;
  snapPoint.bounds.width = Math.max(16 / zoom, 12);
  snapPoint.bounds.height = Math.max(16 / zoom, 12);
  snapPoint.position = center;
}

export function removeHighlightingSnapPoint(referenceGroup: paper.Item, snapPoint: paper.Item) {
  const center = snapPoint.position;
  snapPoint.bounds.width = 8;
  snapPoint.bounds.height = 8;
  snapPoint.position = center;
}

const SNAP_CLOUD_RANGE = 250;

export function toggleSnapCloud(layer: paper.Item, item: paper.Item) {
  const snapCloud = getSnapCloud(item);

  snapCloud.visible = layer.children.some((child: paper.Item) => isSnapCloudRange(snapCloud, child));
}

export function toggleNeighboringSnapClouds(layer: paper.Item, item: paper.Item) {
  layer.children.forEach((child: paper.Item) => {
    if (isTypeReferenceSignMarker(child)) {
      const snapCloud = getSnapCloud(child);
      snapCloud.visible = isSnapCloudRange(snapCloud, item);
    }
  });
}

function isSnapCloudRange(snapCloud: paper.Item, otherItem: paper.Item) {
  if (isTypeLine(otherItem) || isTypeArrow(otherItem)) {
    const line = otherItem.children[0] as paper.PathItem;
    const snapCloudPosition = snapCloud.bounds.center;

    return line.getNearestPoint(snapCloudPosition).getDistance(snapCloudPosition) <= SNAP_CLOUD_RANGE;
  } else if (isTypeBrace(otherItem)) {
    return otherItem.bounds.center.getDistance(snapCloud.bounds.center) <= SNAP_CLOUD_RANGE;
  }
  return false;
}

export function hideAllSnapClouds(layer: paper.Item) {
  layer.children.forEach((child: paper.Item) => {
    if (isTypeReferenceSignMarker(child)) {
      const snapCloud = getSnapCloud(child);
      snapCloud.visible = false;
    }
  });
}

export function findAttachedMarker(layer: paper.Item, symbolSnapPoint: SymbolSnapPoint) {
  return layer.children
    .filter((child: paper.Item) => child.data.snapPoints?.some(
      (it2: SymbolSnapPoint) => symbolSnapPoint.markerSnapPointGuid === it2.guid));
}

export function findAttachedSymbols(layer: paper.Item, markerSnapPoint: MarkerSnapPoint) {
  return layer.children
    .filter((child: paper.Item) => child.data.snapPoints?.some(
      (it2: SymbolSnapPoint) => markerSnapPoint.symbolSnapPointGuids.includes(it2.guid)));
}

export function countActiveSnapPointBottom(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => [
      CompassDirection.SW, CompassDirection.SSW, CompassDirection.S, CompassDirection.SSE, CompassDirection.SE].includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function countActiveSnapPointTop(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => [
      CompassDirection.NW, CompassDirection.NNW, CompassDirection.N, CompassDirection.NNE, CompassDirection.NE].includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function countActiveSnapPointRight(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => [
        CompassDirection.NNE, CompassDirection.NE, CompassDirection.E, CompassDirection.SE, CompassDirection.SSE].includes(it.direction)
      || (referenceSignMarker.referenceSigns.length > 1 && (CompassDirection.N === it.direction || CompassDirection.S === it.direction)))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function countActiveSnapPointLeft(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => [
      CompassDirection.SSW, CompassDirection.SW, CompassDirection.W, CompassDirection.NW, CompassDirection.NNW].includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function hasActiveSnapPoint(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .some(it => it.symbolSnapPointGuids?.length);
}

export const SNAP_CLOUD_SIZE = 8;
export const SNAP_CLOUD_FOR_GROUP_SIZE = 10;

export function isSnapPointRecalculationNeeded(referenceSignMarker: ReferenceSignMarker) {
  const isExpansionNeeded = referenceSignMarker.referenceSigns.length > 1
    && referenceSignMarker.snapPoints.length === SNAP_CLOUD_SIZE;

  const isReductionNeeded = referenceSignMarker.referenceSigns.length === 1
    && referenceSignMarker.snapPoints.length === SNAP_CLOUD_FOR_GROUP_SIZE;

  return isExpansionNeeded || isReductionNeeded;
}

export function transFormSnapPointsForGrownSnapCloud(snapPoint: MarkerSnapPoint,
                                                     snapPointsOld: MarkerSnapPoint[],
                                                     item: paper.Item) {
  const directionMapping: Partial<Record<CompassDirection, CompassDirection>> = {
    [CompassDirection.NNW]: CompassDirection.N,
    [CompassDirection.NNE]: CompassDirection.N,
    [CompassDirection.SSW]: CompassDirection.S,
    [CompassDirection.SSE]: CompassDirection.S
  };
  const otherDirectionMapping: Partial<Record<CompassDirection, CompassDirection>> = {
    [CompassDirection.NNW]: CompassDirection.NNE,
    [CompassDirection.NNE]: CompassDirection.NNW,
    [CompassDirection.SSW]: CompassDirection.SSE,
    [CompassDirection.SSE]: CompassDirection.SSW
  };
  const oldDirection = directionMapping[snapPoint.direction];

  if (!oldDirection) {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => it.direction === snapPoint.direction)
      .flatMap(it => it.symbolSnapPointGuids);
    return;
  }
  const position = new paper.Point(snapPoint.coordinateX, snapPoint.coordinateY);
  const snapPointOld = snapPointsOld.find((it: MarkerSnapPoint) => it.direction === oldDirection);
  const snapPointOldPosition = new paper.Point(snapPointOld!.coordinateX, snapPointOld!.coordinateY);

  const otherDirection = otherDirectionMapping[snapPoint.direction];
  const other = item.data.snapPoints.find((it: MarkerSnapPoint) => it.direction === otherDirection);
  const otherPosition = new paper.Point(other.coordinateX, other.coordinateY);

  snapPoint.symbolSnapPointGuids =
    position.getDistance(snapPointOldPosition) <= position.getDistance(otherPosition)
      ? snapPointOld!.symbolSnapPointGuids
      : [];
}

export function transFormSnapPointsForShrunkSnapCloud(snapPoint: MarkerSnapPoint,
                                                      snapPointsOld: MarkerSnapPoint[]) {
  if (snapPoint.direction === CompassDirection.N) {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => [CompassDirection.NNW, CompassDirection.NNE].includes(it.direction))
      .flatMap(it => it.symbolSnapPointGuids);
  } else if (snapPoint.direction === CompassDirection.S) {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => [CompassDirection.SSW, CompassDirection.SSE].includes(it.direction))
      .flatMap(it => it.symbolSnapPointGuids);
  } else {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => it.direction === snapPoint.direction)
      .flatMap(it => it.symbolSnapPointGuids);
  }
}

export function repairSnapPointPositions(item: paper.Item, snapPoint: MarkerSnapPoint) {
  const symbols = findAttachedSymbols(item.parent, snapPoint);
  if (!symbols) {
    return [];
  }
  return symbols
    .map(symbol => {
      symbol.data.snapPoints
        .filter((it: SymbolSnapPoint) => snapPoint.symbolSnapPointGuids.includes(it.guid))
        .forEach((it: SymbolSnapPoint) => {
          it.markerSnapPointGuid = snapPoint.guid;
          it.coordinateX = snapPoint.coordinateX;
          it.coordinateY = snapPoint.coordinateY;

          if (isTypeLine(symbol) || isTypeArrow(symbol)) {
            const snapPointPosition = new paper.Point(snapPoint.coordinateX, snapPoint.coordinateY);
            const line = symbol.children[0] as paper.Path.Line;
            const segment = it.direction === SymbolSnapDirection.X1 ? line.segments[0] : line.segments[1];

            segment.point = snapPointPosition;
          } else if (isTypeBrace(symbol)) {
            const snapPointPosition = new paper.Point(snapPoint.coordinateX, snapPoint.coordinateY);
            const braceTip = getBraceTip(symbol);
            const offset = snapPointPosition.subtract(braceTip.point);

            symbol.position = symbol.position.add(offset);
          }
        });
      return symbol;
    });
}