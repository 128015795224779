<template>
  <div class="canvas-container"
       :style="{width: clientWidth + 'px', height: clientHeight + 'px'}"
       v-active-element="onActiveElementChange">
    <drop ref="drop" @drop="onDrop($event)" class="canvas-drop">
      <i v-show="isLoading" class="exi-lg exi-large-spinner-unmasked rotating"/>
      <canvas v-show="!isLoading && applicationFigure !== null" ref="figureEditorCanvas"
              id="figureEditorCanvas"
      />
      <span v-show="!isLoading && applicationFigure === null">{{ $t('canvas.noFigureMessage') }}</span>
    </drop>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import * as paper from 'paper';
import * as uuid from 'uuid';
import FigureModule from '@/store/modules/FigureModule';
import {Drop} from 'vue-easy-dnd';
import {
  CANVAS_HIT_OPTIONS,
  CURVE_HANDLE_HIT_OPTIONS,
  FigureEditorDndEvent,
  HelpLineListEntry,
  ReferenceSignUpdate,
  SEGMENT_HIT_OPTIONS,
  SELECTION_RECTANGLE_STYLE,
  SymbolsListEntry
} from '@/store/models/figure.model';
import {
  BRACE_SIZE,
  BraceType,
  BUTTON_ZOOM_FACTOR,
  Curve,
  DEFAULT_CURVE,
  FigureEditorMode,
  FigureSymbol,
  FigureSymbolSubType,
  FigureSymbolType,
  LayerName,
  LINE_LENGTH,
  MarkerSnapPoint,
  MAX_ZOOM,
  MIN_BRACE_LENGTH,
  MIN_SIZE_CURVE_HANDLE,
  MOUSE_ZOOM_FACTOR,
  Orientation,
  PASTE_OFFSET,
  ReferenceSignMarker,
  ReferredReferenceSign,
  SelectionMode,
  SIZES,
  SymbolSnapPoint,
} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {FigureCanvas} from '../../../../shared/drawingbasemodule/src/drawingBaseModule';
import {
  ApplicationFigureEdit,
  CreateApplicationFigureSymbolsEvent,
  DeleteApplicationFigureSymbolsEvent,
  UpdateApplicationFigureSymbolsEvent
} from '@/api/models/applicationFigure.model';
import {CreateSymbolEvent, UpdateSymbolEvent} from '@/api/models/figureSymbol.model';
import {ReferenceSignListEntry} from '@/store/models/referenceSign.model';
import ViewModelChangeTracker from '@/util/ViewModelChangeTracker';
import {
  asGroup,
  detachAllSnapPoints,
  filterOnlyGroups,
  findAttachedMarker,
  findAttachedSymbols,
  getSymbolGuid,
  getSymbolGuidRequired,
  isTypeArrow,
  isTypeBrace,
  isTypeCurve,
  isTypeHelpLine,
  isTypeLine,
  isTypePaletteSymbol,
  isTypeReferenceSignMarker,
  isTypeWithUnderlineSupport,
  ItemPredicate
} from '../../../../shared/drawingbasemodule/src/utils/drawingBaseModule.utils';
import {
  buildReferredReferenceSignList,
  createFigureSymbol,
  createFigureSymbolReferenceSignMarker,
  createHelpLine
} from '@/components/figureEditor/figureEditor.util';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import {ErrorWrapper} from '@/util/error.wrapper';
import {LocalizedMessageKey} from '@/api/models/exception.model';
import {debounce} from 'lodash';
import {ScalingUpdate} from '@/api/services/applicationFigure.api';
import {nextTick} from 'vue';
import ActiveElementDirective from '@/directives/ActiveElementDirective';
import {useDefaultErrorHandling} from '@/errorHandling';
import {CreateHelpLineEvent, UpdateHelpLineEvent} from '@/api/models/helpLine.model';
import {CreateReferenceSignMarkerEvent, UpdateReferenceSignMarkerEvent} from '@/api/models/referenceSignMarker.model';
import log from 'loglevel';

@Component(
  {
    name: 'figureEditorCanvas',
    components: {
      Drop,
    },
    directives: {
      'active-element': ActiveElementDirective
    }
  })
class Canvas extends Vue {

  @Prop({required: true})
  private readonly focusableParent!: HTMLDivElement;

  @Prop({default: 300, required: true})
  clientWidth!: number;

  @Prop({default: 300, required: true})
  clientHeight!: number;

  @Ref('drop')
  private readonly drop!: HTMLElement;

  @Ref('figureEditorCanvas')
  private readonly canvas!: HTMLCanvasElement;

  private debug = false;

  // Set to true after model was loaded from the backend and painted to the UI.
  private viewReady = false;
  private figureCanvas: FigureCanvas | null = null;
  private project!: paper.Project;

  private backgroundLayer!: paper.Layer;
  private foregroundLayer!: paper.Layer;
  private extraInfosLayer!: paper.Layer;
  private helpLinesLayer!: paper.Layer;
  private selectionRectangle: paper.Path | paper.PointText | undefined;

  // If a segment (see paper.Segment) was clicked on: Item and Segment
  private segmentHitItem: paper.Item | undefined;
  private segmentHitSegment: paper.Segment | undefined;

  // If a curve is currently being transformed or just being drawn
  private currentCurve: paper.Group | undefined;
  // If a curve handle (for transforming the curve) was clicked on: Segment-Index and Type ('handle-in' or 'handle-out')
  private curveHandlesHitSegmentIndex: number | undefined;
  private curveHandlesHitType: string | undefined;

  // When clicking on a reference sign marker the timer is started. After 1sec without moving the mouse it is ungrouped.
  private timerForUngrouping: number | undefined;

  // When clicking on the empty canvas this mode is entered and a selection rectangle is drawn.
  private isSelectionRectangleMode = false;

  // Selected items are currently being modified (moved, transformed) and need to be saved.
  private selectedSymbolsNeedSaving = false;

  private width!: number;
  private height!: number;

  private symbolViewChangeTracker = new ViewModelChangeTracker();

  private readonly A4_RATIO = 1 / Math.sqrt(2);
  private readonly MIN_HEIGHT = 0.1;
  private readonly MIN_WIDTH = this.MIN_HEIGHT * this.A4_RATIO;

  private tool!: paper.Tool;
  private leftButtonPressed = false;
  private rightButtonPressed = false;
  private canvasDragged = false;
  private isZoomChanged = false;
  private isMouseOverCanvas = false;

  private deletionError = new ErrorWrapper(LocalizedMessageKey.FIGURE_RENDERING_DELETION_ERROR);

  private TIMEOUT = 500;

  private debounceScaling = debounce((payload: ScalingUpdate) => FigureModule.updateScaling(payload), this.TIMEOUT);
  private debounceFigureNumber = debounce((payload: { guid: string; showFigureNumber: boolean }) =>
                                            FigureModule.updateNumberShown(payload), this.TIMEOUT);

  private activeElement: Element | null = null;

  /**
   * Calculate a diff between view and store change tracker.
   * Apply the changes (added, updated, removed symbols) to the view.
   * @private
   */
  public syncStoreToView(keepSelection: boolean = false) {
    const symbolDiff = this.symbolViewChangeTracker.calcDiff(FigureModule.symbolTracker);
    if (this.viewReady && symbolDiff.changed) {
      this.logDebug("Syncing symbols from store to view...", symbolDiff);
      symbolDiff.removedGuids.forEach(guid => this.removeSymbolFromCanvas(guid));
      symbolDiff.updatedGuids.forEach(guid => this.updateSymbolOnCanvas(this.getSymbol(guid)));
      if (symbolDiff.addedGuids.length > 0) {
        if (!keepSelection) {
          this.clearSelection();
        }
        symbolDiff.addedGuids.forEach(guid => this.addSymbolToCanvas(this.getSymbol(guid)), SelectionMode.SELECT);
      }
    }
    // We additionally need to consider the state of the foregroundLayer
    // On this paperjs.Layer, all symbols are rendered, meaning that if it gets out of sync, the symbols aren't shown correctly
    // This leads to inconsistency in which symbols might disappear even though they're present or the other way around
    // This forces the user to reload the editor quite often, espacially as adding many symbols in sequence causes this issue
    // The proposed code is supposed to be a TEMPORARY Fix. At some point the syncronisation has to be fixed in a more robust fix, as this is more a workaround
    //
    // For now we do not add handling for deleting objects which shouldn't be there as it appears basically never and isn't particularly easy to implement
    // First there has to be a decision on the process of adding symbols

    // look through all items in the view
    if (this.foregroundLayer) {
      this.symbolViewChangeTracker.getAllGuids().forEach(guid => {
        // if an item appears in the foregroundlayer, skip
        if (this.foregroundLayer.children.some((item) => item.name === guid)) {
          return;
        }
        // if it doesn't appear, add it
        this.addSymbolToCanvas(this.getSymbol(guid), SelectionMode.KEEP);
      });
    }
  }

  /**
   * Find a FigureSymbol in the store by GUID.
   * @public
   */
  getSymbol(guid: string): FigureSymbol {
    const item = FigureModule.symbol(guid);
    if (!item) {
      throw new Error("Symbol not found in FigureModule: " + guid);
    }
    return item;
  }

  getReferenceSignMarkersForRefSign(guid: string) {
    return FigureModule.findAllReferenceSignMarkersContaining(guid);
  }

  get isLoading(): boolean {
    return FigureModule.isLoading;
  }

  get figureEditorMode(): FigureEditorMode {
    return FigureModule.figureEditorMode;
  }

  get backEndUpdatedSymbol(): boolean {
    return FigureModule.symbolsFromBackend;
  }

  get hasFocus(): boolean {
    return this.activeElement === this.focusableParent;
  }

  private onActiveElementChange(element: Element | null) {
    this.activeElement = element;
  }

  @Watch('figureEditorMode', {immediate: true})
  private figureEditorModeChanged(newFigureEditorMode: FigureEditorMode) {
    this.logDebug('figureEditorModeChanged', newFigureEditorMode);
    // If we switched to a non curve mode, we need to remove the current curve if exists
    if (!this.isCreateCurveMode && this.currentCurve && !this.currentCurve.data.isEndpointSet) {
      this.removeCurve();
    }
  }

  get isCreateCurveMode(): boolean {
    return this.figureEditorMode === FigureEditorMode.DASHED_CURVE || this.figureEditorMode === FigureEditorMode.SOLID_CURVE;
  }

  get isZoomSelectionMode(): boolean {
    return this.figureEditorMode === FigureEditorMode.ZOOM_SELECTION;
  }

  /**
   * Computes the 100% zoom factor based on the current window dimensions.
   */
  get current100ZoomFactor(): number {
    return Math.min(
      Math.max(Math.floor(this.clientWidth * 0.95), this.MIN_WIDTH) / this.width,
      Math.max(Math.floor(this.clientHeight * 0.95), this.MIN_HEIGHT) / this.height);
  }

  get applicationFigure(): ApplicationFigureEdit | null {
    return FigureModule.applicationFigure;
  }

  @Watch('applicationFigure', {immediate: true})
  onApplicationFigureChanged(): void {
    if (!this.canvas) {
      return;
    }
    this.removeListeners();

    const applicationFigure = this.applicationFigure;
    if (applicationFigure === null) {
      this.logDebug('application figure is missing');
      return;
    }

    this.symbolViewChangeTracker.reset();
    this.initializeCanvas(applicationFigure);

    const symbols = applicationFigure.figureSymbols;

    // Gets the paperJs information from DrawingBaseModule
    this.figureCanvas = new FigureCanvas(
      {
        figureInfo: {
          position: applicationFigure.position,
          totalFiguresInDocument: applicationFigure.totalFiguresInDocument,
          scaling: applicationFigure.scaling,
          showFigureNumber: applicationFigure.showFigureNumber,
          orientation: applicationFigure.orientation,
          image: applicationFigure.image,
          imageType: applicationFigure.imageType,
          symbols: symbols,
        },
        config: {
          withHelpLines: true,
          withSymbols: true,
          withBackground: true,
          withInfoTexts: true,
          withUserHelpLines: true
        }
      },
      this.width, this.height
    );

    this.figureCanvas.getProject().then((drawingBaseProject) => {
      // Create an empty project and a view for the canvas
      this.project = new paper.Project(this.canvas);
      // Create a Tool for events listening
      if (this.tool) {
        this.tool.remove();
        this.canvas.removeEventListener('wheel', (event: WheelEvent) => this.onMouseWheel(event));
        this.canvas.addEventListener('mouseenter', this.onCanvasMouseEnter);
        this.canvas.removeEventListener('mouseleave', this.onCanvasMouseLeave);
        this.canvas.removeEventListener('contextmenu', this.onContextMenu);

        window.removeEventListener('keyup', this.onKeyUp);
      }
      this.tool = new paper.Tool();
      this.canvas.addEventListener('wheel', (event: WheelEvent) => this.onMouseWheel(event));
      this.canvas.addEventListener('mouseenter', this.onCanvasMouseEnter);
      this.canvas.addEventListener('mouseleave', this.onCanvasMouseLeave);
      this.canvas.addEventListener('contextmenu', this.onContextMenu);

      window.addEventListener('keyup', this.onKeyUp);
      this.addListeners();

      // Copy the layers from the drawingBaseProject to the project in the client so it can be manipulated
      this.initializeLayers(drawingBaseProject);
      this.onToggleHelpLines(this.showHelpLines);

      // Track them for the view
      symbols.forEach(it => this.registerInChangeTracker(it.guid));

      // Centers the canvas
      this.onFullZoomOut();

      this.viewReady = true;
      this.syncStoreToView();
      // Ensure canvas has correct size, Watcher on dimensions may be dismissed if paperjs is not fully initialized
      this.doNextTick(() => this.adjustCanvas(this.dimensions.width, this.dimensions.height));
    });
  }

  /**
   * Handles the event when the mouse enters the canvas area.
   * Sets the `isMouseOverCanvas` flag to `true`.
   *
   * @private
   */
  private onCanvasMouseEnter() {
    this.isMouseOverCanvas = true;
  }

  /**
   * Handles the event when the mouse leaves the canvas area.
   * Sets the `isMouseOverCanvas` flag to `false` and removes the help line preview.
   *
   * @private
   */
  private onCanvasMouseLeave() {
    this.isMouseOverCanvas = false;
    this.removeHelpLinePreview();
  }

  get referenceSigns() {
    return ReferenceSignModule.referenceSignListEntries;
  }

  @Watch('referenceSigns', {immediate: true, deep: true})
  onReferenceSignChange(newValue: ReferenceSignListEntry[], oldValue: ReferenceSignListEntry[] | undefined): void {
    if (!oldValue) {
      return;
    }
    const changedSigns: ReferenceSignUpdate[] = [];

    for (const newRefSign of newValue) {
      if (oldValue.find(oldRefSign => oldRefSign.guid === newRefSign.guid) !== undefined) {
        changedSigns.push({refSignGuid: newRefSign.guid!, newLabel: newRefSign.labelResulting, newName: newRefSign.name});
      }
    }

    const deletedSigns: string[] = [];
    for (const oldRefSign of oldValue) {
      if (newValue.find(newRefSign => newRefSign.guid === oldRefSign.guid) === undefined) {
        deletedSigns.push(oldRefSign.guid!);
      }
    }

    FigureModule.updateReferenceSignLabelsAndNames(
      {
        changeRefSigns: changedSigns,
        deletedReferenceSignGuids: deletedSigns
      });
    this.syncStoreToView();
  }

  @Watch('backEndUpdatedSymbol')
  onSymbolsFromBackend() {
    this.syncStoreToView(true);
    FigureModule.setSymbolsFromBackend(false);
  }

  @Watch('showHelpLines')
  onToggleHelpLines(newValue: boolean) {
    this.foregroundLayer.children
      .forEach((it: paper.Item) => {
        if (isTypeHelpLine(it)) {
          it.visible = newValue;
        }
      });
  }

  get showHelpLines() {
    return FigureModule.showHelpLines;
  }

  /**
   * Initializes the canvas HTML object with the right dimensions and settings.
   * @param applicationFigure The ApplicationFigureEdit object to be represented on the paperJs-based canvas object in the component.
   * @private
   */
  private initializeCanvas(applicationFigure: ApplicationFigureEdit): void {
    // Size doesn't really matter, we set the zoom accordingly. We just need the right proportions for LANDSCAPE and PORTRAIT.
    const size = SIZES.A4[applicationFigure.orientation];
    this.width = size.width;
    this.height = size.height;

    this.setCanvasSize();

    // Apply smoothing quality in canvas
    const context = this.canvas.getContext('2d');
    if (context) {
      context.imageSmoothingQuality = 'high';
      context.imageSmoothingEnabled = true;
    }
  }

  /**
   * Sets the size to the canvas and resizes the canvas to the appropriate size.
   * @private
   */
  private setCanvasSize(): void {
    this.canvas.width = this.clientWidth;
    this.canvas.height = this.clientHeight;
  }

  /**
   * Adds some event listeners to the paperJs-based canvas object in the component and to window object.
   * @private
   */
  private addListeners(): void {
    this.tool.onMouseDrag = (event: paper.ToolEvent) => this.onToolMouseDrag(event);
    this.tool.onMouseDown = (event: paper.ToolEvent) => this.onToolMouseDown(event);
    this.tool.onMouseUp = (event: paper.ToolEvent) => this.onToolMouseUp(event);
    this.tool.onMouseMove = (event: paper.ToolEvent) => this.onToolMouseMove(event);
  }

  /**
   * Initializes the various layers of the paperJs-based canvas object in the component.
   * @param drawingBaseProject The paper.Project object containing the layer information to be initialized.
   * @private
   */
  private initializeLayers(drawingBaseProject: paper.Project): void {
    this.initializeBackgroundLayer(drawingBaseProject);
    this.initializeInfoTextLayers(drawingBaseProject);
    this.initializeForegroundLayer(drawingBaseProject);
    this.initializeHelpLinesLayer(drawingBaseProject);
  }

  /**
   * Initializes the paperJs layer containing the helplines.
   * @param drawingBaseProject The paper.Project object containing the layer information to be initialized.
   * @private
   */
  private initializeHelpLinesLayer(drawingBaseProject: paper.Project): void {
    const helpLinesLayer = drawingBaseProject.layers.find((layer: paper.Layer) => layer.name === LayerName.HELP_LINES);
    if (helpLinesLayer) {
      this.helpLinesLayer = new paper.Layer(helpLinesLayer.children);
      this.helpLinesLayer.name = LayerName.HELP_LINES;
    }
  }

  /**
   * Initializes the paperJs layer containing the symbols.
   * @param drawingBaseProject The paper.Project object containing the layer information to be initialized.
   * @private
   */
  private initializeForegroundLayer(drawingBaseProject: paper.Project): void {
    const foregroundLayer = drawingBaseProject.layers.find((layer: paper.Layer) => layer.name === LayerName.SYMBOLS);
    if (foregroundLayer) {
      this.foregroundLayer = new paper.Layer(foregroundLayer.children);
      this.foregroundLayer.name = LayerName.SYMBOLS;
    }
  }

  /**
   * Initializes the paperJs layer containing the information texts: X/Y and Fig.X
   * @param drawingBaseProject The paper.Project object containing the layer information to be initialized.
   * @private
   */
  private initializeInfoTextLayers(drawingBaseProject: paper.Project): void {
    const extraInfosLayer = drawingBaseProject.layers.find((layer: paper.Layer) => layer.name === LayerName.FIGURE_INFO_TEXTS);
    if (extraInfosLayer) {
      this.extraInfosLayer = new paper.Layer(extraInfosLayer.children);
      this.extraInfosLayer.name = LayerName.FIGURE_INFO_TEXTS;
      // Has to be set explicitly, as property will be lost during init
      this.extraInfosLayer.visible = extraInfosLayer.visible;
    }
  }

  /**
   * Initializes the paperJs background layer.
   * @param drawingBaseProject The paper.Project object containing the layer information to be initialized.
   * @private
   */
  private initializeBackgroundLayer(drawingBaseProject: paper.Project): void {
    const backgroundLayer = drawingBaseProject.layers.find((layer: paper.Layer) => layer.name === LayerName.BACKGROUND);
    if (backgroundLayer) {
      this.backgroundLayer = new paper.Layer(backgroundLayer.children);
      this.backgroundLayer.name = LayerName.BACKGROUND;
    }
  }

  /**
   * Creates a valid guid with uuid v4 format.
   * @private
   */
  private createGuid() {
    return uuid.v4();
  }

  /**
   * Gets the guid of the figure symbol layer in the figure.
   * @private
   */
  private getFigureGuid(): string {
    return this.applicationFigure?.guid as string;
  }

  /**
   * Change underlining status of every selected item.
   * @public
   */
  changeUnderliningSelected(): void {
    this.getSelectedSymbols()
      .filter(item => isTypeWithUnderlineSupport(item))
      .forEach(item => this.changeUnderlining(item));
  }

  /**
   * Change underlining status of item (i.e. reference sign marker).
   * @private
   */
  private changeUnderlining(item: paper.Group) {
    if (isTypeReferenceSignMarker(item)) {
      const shouldBeUnderlined = !this.figureCanvas!.isReferenceSignMarkerUnderlined(item);
      this.underlineReferenceSignMarkerInStore(item, shouldBeUnderlined);
      this.figureCanvas!.changeReferenceSignMarkerUnderlining(item, shouldBeUnderlined);
    }
  }

  /**
   * Underlines the given paper.Item object representing a ReferenceSignMarker symbol.
   * @param item The paper.Item object to be underlined.
   * @param shouldBeUnderlined Whether item should get underlined or not.
   * @private
   */
  private underlineReferenceSignMarkerInStore(item: paper.Group, shouldBeUnderlined: boolean): void {
    FigureModule.updateReferenceSignMarker(
      {
        guid: getSymbolGuidRequired(item),
        underlined: shouldBeUnderlined,
        vertical: item.data.vertical,
        horizontal: item.data.horizontal
      })
      .catch(useDefaultErrorHandling);
  }

  /**
   * Removes the curves that have start point but no end points.
   * @private
   */
  private removeUnfinishedCurve(): void {
    this.logDebug('removeUnfinishedCurve', this.currentCurve, this.currentCurve?.data.isEndpointSet, this.isCreateCurveMode);
    if (this.currentCurve && !this.currentCurve.data.isEndpointSet && this.isCreateCurveMode) {
      this.removeCurve();
    }
  }

  /**
   * Removes the current curve.
   * @private
   */
  private removeCurve(): void {
    this.logDebug('removeCurve', this.currentCurve);
    if (!this.currentCurve) {
      return;
    }

    this.unlockItem(this.currentCurve);
    this.currentCurve.remove();
    this.currentCurve = undefined;
    this.logDebug('removeCurve -> this.currentCurve = undefined');
  }

  /**
   * Called when a user drags a symbol from the palette or a reference sign.
   * @param event The FigureEditorDndEvent to be handled.
   * @private
   */
  private onDrop(event: FigureEditorDndEvent): void {
    if (event.data) {
      this.removeUnfinishedCurve();

      if (!paper.view) {
        return;
      }
      const point = paper.view.viewToProject(this.getCanvasPoint(event.position));
      const originalPoint = this.figureCanvas!.getOriginalPoint(point);
      const createEvent: any = this.buildCreateSymbolEvent(event.data.type, event.data, originalPoint);

      if (createEvent.symbolType === FigureSymbolType.REFERENCE_SIGN_MARKER) {
        if (this.tryCombiningWithReferenceSignMarker(createEvent as CreateReferenceSignMarkerEvent, point)) {
          return;
        }
        const createEvents = [createEvent as CreateReferenceSignMarkerEvent];
        this.addReferenceSignMarkerByCreateEvent(createEvents, SelectionMode.SELECT_EXCLUSIVELY);

        // Add the new referenceSignMarkers to the backend.
        FigureModule.addReferenceSignMarker(createEvents)
          .then(() => this.syncStoreToView(true))
          .catch(useDefaultErrorHandling);
      } else if (createEvent.symbolType === FigureSymbolType.HELP_LINE) {
        const createEvents = [createEvent as CreateHelpLineEvent];
        this.removeHelpLinePreview();
        this.addHelpLineByCreateEvent(createEvents, SelectionMode.SELECT_EXCLUSIVELY);

        // Add the new help lines to the backend.
        FigureModule.addHelpLines(createEvents)
          .then(() => this.syncStoreToView(true))
          .catch(useDefaultErrorHandling);
      } else {
        const createEvents = [createEvent as CreateSymbolEvent];
        this.addSymbolByCreateEvent(createEvents, SelectionMode.SELECT_EXCLUSIVELY);

        // Add the new symbols to the backend.
        FigureModule.addSymbols(createEvents)
          .then(() => this.syncStoreToView(true))
          .catch(useDefaultErrorHandling);
      }
    }
  }

  private addReferenceSignMarkerByCreateEvent(referenceSignMarkerEvents: CreateReferenceSignMarkerEvent[], selectionMode: SelectionMode = SelectionMode.SELECT): void {
    // We don't wait for the backend answer but simulate the resulting FigureSymbol model.
    referenceSignMarkerEvents.forEach(it => {
      const referenceSignMarker = createFigureSymbolReferenceSignMarker(it);
      // We convert it to a paper item and add it to the canvas. It is updated when the backend answer finally arrives.
      const item = this.addSymbolToCanvas(referenceSignMarker, selectionMode);

      Object.assign(it, item.data.boundingBox);
      Object.assign(it, {snapPoints: item.data.snapPoints});
    });
  }

  private addHelpLineByCreateEvent(helpLineEvents: CreateHelpLineEvent[], selectionMode: SelectionMode = SelectionMode.SELECT): void {
    // We don't wait for the backend answer but simulate the resulting FigureSymbol model.
    helpLineEvents.forEach(it => {
      const helpLine = createHelpLine(it);
      // We convert it to a paper item and add it to the canvas. It is updated when the backend answer finally arrives.
      this.addSymbolToCanvas(helpLine, selectionMode);
    });
  }

  /**
   * Handle create event: send it to the backend and add the resulting symbol to the canvas.
   * @private
   */
  private addSymbolByCreateEvent(symbolEvents: CreateSymbolEvent[], selectionMode: SelectionMode = SelectionMode.SELECT): void {
    // We don't wait for the backend answer but simulate the resulting FigureSymbol model.
    symbolEvents.forEach(it => {
      const figureSymbol = createFigureSymbol(it);
      // We convert it to a paper item and add it to the canvas. It is updated when the backend answer finally arrives.
      const item = this.addSymbolToCanvas(figureSymbol, selectionMode);

      if (isTypeLine(item) || isTypeArrow(item) || isTypeBrace(item)) {
        Object.assign(it, {snapPoints: item.data.snapPoints});
      }
    });
  }

  /**
   * Try combining a new ReferenceSignMarker (represented by CreateSymbolEvent) with another ReferenceSignMarker on the canvas.
   * This is only possible if the symbolEvent represents a ReferenceSignMarker AND there is a ReferenceSignMarker at the given position
   * AND the contained reference signs are combinable (not subsets of one another).
   *
   * @param createEvent might represent a new ReferenceSignMarker (or any other symbol)
   * @param point drop position
   * @return true, if the new event could be combined with an exisiting ReferenceSignMarker; false otherwise.
   * @private
   */
  private tryCombiningWithReferenceSignMarker(createEvent: CreateReferenceSignMarkerEvent, point: paper.Point): boolean {
    const hitRefSignMarker = this.filterHitItems(point)
      .find(item => isTypeReferenceSignMarker(item));

    if (hitRefSignMarker) {
      const refSignGuids = this.getReferenceSignGuids(hitRefSignMarker);
      const addedRefSignGuids = createEvent.referenceSigns || [];
      if (this.canReferenceSignMarkersBeCombined(refSignGuids, addedRefSignGuids)) {
        const combinedRefSignGuids: string[] = refSignGuids.concat(addedRefSignGuids);
        this.updateReferenceSignMarkerToNewReferences(hitRefSignMarker, combinedRefSignGuids);
        return true;
      }
    }
    return false;
  }

  /**
   * Are the two sets of reference signs combinable (i.e. not subsets of one another)?
   * @private
   */
  private canReferenceSignMarkersBeCombined(refSignGuids1: string[], refSignGuids2: string[]): boolean {
    const subset1of2 = refSignGuids1.every(it => refSignGuids2.includes(it));
    const subset2of1 = refSignGuids2.every(it => refSignGuids1.includes(it));
    return !subset1of2 && !subset2of1;
  }

  /**
   * Add symbol to the canvas.
   * Create a symbol item from the symbol model, add it to the canvas and set the selection mode appropriately.
   *
   * @param newSymbol model for the new symbol
   * @param selectionMode e.g. select additionally or exclusively
   * @private
   */
  private addSymbolToCanvas(newSymbol: FigureSymbol, selectionMode: SelectionMode = SelectionMode.SELECT): paper.Item {
    const newItem = this.figureCanvas!.createSymbol(newSymbol);

    this.foregroundLayer.activate();
    this.foregroundLayer.addChild(newItem);
    this.changeSelection(newItem, selectionMode);

    this.registerInChangeTracker(newSymbol.guid);
    return newItem;
  }

  /**
   * Update cursor style after every mouse event:
   * Style can either be default, or, if any item is hit with the mouse cursor, "grab" or "grabbing" (clicked).
   * @private
   */
  private updateCursorStyle(where: paper.Point) {
    // default cursor
    let cursorStyle: string | null = null;
    if (this.canvasDragged) {
      cursorStyle = 'grabbing';
    } else if (this.isZoomSelectionMode) {
      cursorStyle = 'zoom-in';
    } else if (this.getHitItem(where)) {
      // if any item (including curve handles) is hit: use "grabbing" or "grab"
      cursorStyle = (this.leftButtonPressed || this.rightButtonPressed) ? 'grabbing' : 'grab';
    }
    this.project.view.element.style.setProperty('cursor', cursorStyle);
  }

  /**
   * Gets the canvas paper.Point object from the given client position.
   * @param clientPosition The client position to be translated.
   * @return The resulting paper.Point for the given client position.
   * @private
   */
  private getCanvasPoint(clientPosition: { x: number; y: number }): paper.Point {
    const rect: DOMRect = this.canvas.getBoundingClientRect();
    return new paper.Point(clientPosition.x - rect.left, clientPosition.y - rect.top);
  }

  /**
   * Select all the items that are enclosed within the selection rectangle or handles the zoom selection.
   * @private
   */
  private finishSelectionRectangle(): void {
    if (!this.selectionRectangle) {
      return;
    }

    switch (this.figureEditorMode) {
      case FigureEditorMode.SELECTION: {
        this.foregroundLayer?.children.forEach((childItem: paper.Item) => {
          if (childItem.isInside((this.selectionRectangle as paper.Item).bounds)) {
            this.selectItem(childItem);
          }
        });
        break;
      }
      case FigureEditorMode.ZOOM_SELECTION: {
        this.onZoomSelection();
        break;
      }
      default: {
        break;
      }
    }

    // Delete the selection rectangle
    this.selectionRectangle.remove();
    this.selectionRectangle = undefined;
  }

  /**
   * Handles the zoom selection based on the selection rectangle when the ZOOM_SELECTION mode is active.
   * @private
   */
  private onZoomSelection(): void {
    if (!this.selectionRectangle) {
      return;
    }

    if (!paper.view) {
      return;
    }

    const selectionBounds = this.selectionRectangle.bounds;
    const newZoom = Math.min(this.clientWidth / selectionBounds.width, this.clientHeight / selectionBounds.height);
    log.info(`onZoomSelection: newZoom=${newZoom} selectionBounds.width=${selectionBounds.width} selectionBounds.height=${selectionBounds.height} clientWidth=${this.clientWidth} clientHeight=${this.clientHeight}`);
    this.logDebug('onZoomSelection', selectionBounds, newZoom);

    // Zooms in: Makes sure that the MAX_ZOOM is always applied even when the new zoom factor is too big
    this.setZoom(this.isValidZoomIn(newZoom) ? newZoom : MAX_ZOOM);

    // Centers the canvas based on the selection rectangle
    paper.view.center = selectionBounds.center;

    // Toggles the zoom selection mode
    FigureModule.activateSelectionMode();
  }

  /**
   * Saves the current curve.
   * @private
   */
  private saveNewCurve(): void {
    const curve = this.currentCurve;
    if (!curve || !this.isCreateCurveMode) {
      return;
    }
    if (!curve.data.isStartpointSet || !curve.data.isEndpointSet) {
      return;
    }
    const event = this.buildCurveCreateSymbolEvent(curve);
    const updatedSymbol = this.buildCurveSymbol(curve);
    this.updateSymbolOnCanvas(updatedSymbol);

    FigureModule.addSymbol(event)
      .catch(useDefaultErrorHandling);

    this.unlockItem(curve);
    this.currentCurve = undefined;
    this.logDebug('saveNewCurve -> this.currentCurve = undefined');
  }

  public saveSymbol(items: paper.Item[], useOffset: boolean): void {
    if (items) {
      this.clearSelection();

      const offset = useOffset ? PASTE_OFFSET : 0;
      const event = this.buildFigureSymbolsCreateEvent(items, offset);

      FigureModule.createFigureSymbols(event)
        .catch(useDefaultErrorHandling);

      if (event.referenceSignMarkers.length) {
        this.addReferenceSignMarkerByCreateEvent(event.referenceSignMarkers);
      }
      if (event.helpLines.length) {
        this.addHelpLineByCreateEvent(event.helpLines);
      }
      if (event.symbols.length) {
        this.addSymbolByCreateEvent(event.symbols);
      }
    }
  }

  public selectAllItems(): void {
    const symbolLayer = this.project.layers.filter((layer) => layer.name === "symbols")[0];
    const allSymbols = symbolLayer.getItems({});
    allSymbols.forEach((symbol) => this.changeSelection(symbol, SelectionMode.SELECT));
  }

  private calculateCurrentVersionofRefSign(item: paper.Item, offset: number): CreateReferenceSignMarkerEvent | undefined {
    const originalPoint = this.figureCanvas!.getOriginalPoint(item.bounds.center).add(offset);
    const group = asGroup(item);

    if (group) {
      const validRefSigns = ReferenceSignModule.referenceSignListEntries;
      const validRefSignGuids = validRefSigns.map((refSign) => refSign.guid);
      const allPastedRefSigns = this.getReferenceSignGuids(group)
      const remainingValidRefSigns = allPastedRefSigns.filter((guid) => {
        return validRefSignGuids.includes(guid);
      });
      const invalidRefSigns = remainingValidRefSigns.length < allPastedRefSigns.length;
      if (remainingValidRefSigns.length === 0) {
        // Should this show an error?
        return;
      }
      return this.buildReferenceSignMarkerCreateEventOnDrop(
        invalidRefSigns ? remainingValidRefSigns : this.getReferenceSignGuids(group),
        item.data.underlined,
        originalPoint,
        item.data.snapPoints);
    }
  }

  /**
   * Updates the current curve.
   * @private
   */
  private updateTransformedCurve(): void {
    const curve = this.currentCurve;
    const isEndpointSet = this.currentCurve?.data?.isEndpointSet;
    this.logDebug('updateTransformedCurve', curve, isEndpointSet, this.curveHandlesHitType, this.isCreateCurveMode);
    if (!curve || !this.curveHandlesHitType) {
      return;
    }

    this.unlockItem(curve);
    this.currentCurve = undefined;
    this.logDebug('updateTransformedCurve -> this.currentCurve = undefined');
    const updatedSymbol = this.buildCurveSymbol(curve);
    this.updateSymbolOnCanvas(updatedSymbol);

    const event = this.buildCurveUpdateSymbolEvent(curve);

    FigureModule.updateSymbol(event)
      .catch(useDefaultErrorHandling);
  }

  /**
   * Save moved items.
   * @private
   */
  private onMoveItemsEnd(): void {
    this.logDebug('onMoveItemsEnd', this.selectedSymbolsNeedSaving);
    if (!this.selectedSymbolsNeedSaving) {
      return;
    }
    const numOfReferenceSigns = this.getSelectedSymbols().filter(isTypeReferenceSignMarker).length;
    const numOfOtherItems = this.getSelectedSymbols().filter(item => !isTypeReferenceSignMarker(item)).length;

    // allow merge of reference signs when moved only when exactly one reference sign is selected and no other item is selected
    const allowMergeOfReferenceSigns = numOfReferenceSigns === 1 && numOfOtherItems === 0;

    if (allowMergeOfReferenceSigns) {
      const [item] = this.getSelectedSymbols();
      this.moveReferenceSignMarker(item, allowMergeOfReferenceSigns);
    } else {
      const event = this.buildFigureSymbolsUpdateEvent(this.getSelectedSymbols());

      FigureModule.updateFigureSymbols(event)
        .catch(useDefaultErrorHandling);
    }
    this.getSelectedSymbols()
      .forEach((item) => this.unlockItem(item));

    this.figureCanvas!.onMoveItemsEnd(this.foregroundLayer);

    // In case we handled a curve (segment)
    this.currentCurve = undefined;
    this.logDebug('onMoveItemsEnd -> this.currentCurve = undefined');
    this.selectedSymbolsNeedSaving = false;
  }

  /**
   * Handles a movement related to ReferenceSignMarker.
   * @param markerItem The paper.Item object representing a ReferenceSignMarker in the canvas.
   * @param allowMerge Specifies if the marker item is allowed to merge with another item below it to form a reference sign group.
   * @private
   */
  private moveReferenceSignMarker(markerItem: paper.Group, allowMerge: boolean): void {
    const intersectedRefSignMarker = this.filterIntersectingItems(markerItem)
      .find(item => isTypeReferenceSignMarker(item));

    if (!intersectedRefSignMarker) {
      FigureModule.updateReferenceSignMarker(this.buildReferenceSignMarkerUpdateEvent(markerItem))
        .catch(useDefaultErrorHandling);
      return;
    }
    const marker1RefSignGuids = this.getReferenceSignGuids(intersectedRefSignMarker);
    const marker2RefSignGuids = this.getReferenceSignGuids(markerItem);

    if (allowMerge && this.canReferenceSignMarkersBeCombined(marker1RefSignGuids, marker2RefSignGuids)) {
      this.combineReferenceSignMarkers(marker1RefSignGuids, marker2RefSignGuids, markerItem, intersectedRefSignMarker);
      return;
    }
    FigureModule.updateReferenceSignMarker(this.buildReferenceSignMarkerUpdateEvent(markerItem))
      .catch(useDefaultErrorHandling);
  }

  /**
   * Handler for MouseDrag event on paper.Tool object.
   * @param event The ToolEvent to be handled.
   * @private
   */
  private onToolMouseDrag(event: paper.ToolEvent): void {
    if (this.canvasDragged) {
      if (!paper.view) {
        return;
      }

      // Drag the canvas
      const panOffset = event.point.subtract(event.downPoint);
      paper.view.center = paper.view.center.subtract(panOffset);

    } else {
      if (this.timerForUngrouping) {
        clearTimeout(this.timerForUngrouping);
      }

      if (this.selectionRectangle) {
        this.selectionRectangle.remove();
      }

      if (this.isSelectionRectangleMode && !this.isCreateCurveMode) {
        this.drawSelectionRectangle(event.downPoint, event.point);
      } else if (this.segmentHitSegment && !isTypeReferenceSignMarker(this.segmentHitItem!)) {
        this.transformSymbol(event);
      } else {
        this.moveItem(event);
      }
    }

    // Update cursor style only after items have been moved to their new positions. This way we get a stable "grabbing" style.
    this.updateCursorStyle(event.point);
  }

  /**
   * Handler for MouseDown event on paper.Tool object.
   * @param toolEvent The ToolEvent to be handled.
   * @private
   */
  private onToolMouseDown(toolEvent: paper.ToolEvent): void {
    this.logDebug('onToolMouseDown', toolEvent);
    // ToolEvent contains the underlying MouseDown event as an internal field
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mouseEvent: MouseEvent = (toolEvent as any)?.event;
    this.leftButtonPressed = !!(mouseEvent?.buttons & 1);
    this.rightButtonPressed = !!(mouseEvent?.buttons & 2);
    this.canvasDragged = this.rightButtonPressed;
    this.updateCursorStyle(toolEvent.point);

    if (this.canvasDragged) {
      return;
    }

    this.curveHandlesHitType = undefined;
    this.curveHandlesHitSegmentIndex = undefined;
    const where = toolEvent.point;

    this.foregroundLayer.activate();

    // Checks whether any item on the canvas has been clicked or not
    const hitItem = this.isCreateCurveMode ? null : this.getHitItem(where);

    if (hitItem) {
      this.handleHitOnSymbol(hitItem, where);
    } else {
      // The user has not clicked on any element on the canvas or is in curve creation mode
      this.handleHitOnCanvas(where);
    }
    this.isSelectionRectangleMode = !hitItem;
  }

  /**
   * Handler for MouseUp event on paper.Tool object.
   * @private
   */
  private onToolMouseUp(event: paper.ToolEvent): void {
    if (this.timerForUngrouping) {
      clearTimeout(this.timerForUngrouping);
    }
    this.logDebug('onToolMouseUp', event);

    // Save moved items.
    this.onMoveItemsEnd();

    // Save transformed curve
    this.updateTransformedCurve();

    // Try to save newly created curve (with curve toolbar)
    this.saveNewCurve();

    // Select all the items that are enclosed within the selection rectangle
    this.finishSelectionRectangle();

    this.segmentHitSegment = undefined;
    this.segmentHitItem = undefined;
    this.canvasDragged = false;
    this.leftButtonPressed = false;
    this.rightButtonPressed = false;

    this.updateCursorStyle(event.point);
  }

  /**
   * Handler for MouseMove event on paper.Tool object.
   *
   * @private
   */
  private onToolMouseMove(event: paper.ToolEvent): void {
    if (!this.foregroundLayer) {
      return;
    }
    if (this.isMouseOverCanvas) {
      this.showHelpLinePreview(event.point);
    }
    this.updateCursorStyle(event.point);
  }

  /**
   * Displays a preview of the help line at the specified location.
   *
   * @param where The location on the canvas where the preview should be shown
   * @private
   */
  private showHelpLinePreview(where: paper.Point) {
    const dragElements = document.getElementsByClassName('drag-source');

    if (!dragElements.length) {
      return;
    }
    const orientation = this.getDragElementOrientation(dragElements[0]);

    if (!orientation) {
      return;
    }
    const originalPoint = this.figureCanvas!.getOriginalPoint(where);
    const helpLinePreview = this.findOnCanvas(FigureModule.helpLinePreviewGuid)
      || this.createHelpLinePreview(orientation, originalPoint);

    helpLinePreview.position = originalPoint;
    this.figureCanvas!.onHelpLineMoved(helpLinePreview);
  }

  /**
   *
   * @param dragElement
   * @private
   */
  private getDragElementOrientation(dragElement: Element): Orientation | null {
    if (dragElement) {
      const iconElement = dragElement.querySelector('i');

      if (iconElement) {
        const iconClasses = iconElement.classList;

        if (iconClasses.contains('exs-horizontal-help-line')) {
          return Orientation.HORIZONTAL;
        } else if (iconClasses.contains('exs-vertical-help-line')) {
          return Orientation.VERTICAL;
        }
      }
    }
    return null;
  }

  /**
   * Creates a help line preview on the canvas based on the specified orientation and original point.
   *
   * @param orientation The orientation of the help line (e.g., HORIZONTAL or VERTICAL)
   * @param originalPoint The original point on the canvas where the help line preview should be created.
   * @private
   */
  private createHelpLinePreview(orientation: Orientation, originalPoint: paper.Point): paper.Group {
    const helpLinePreview = this.figureCanvas!.createHelpLine(
      {
        guid: FigureModule.helpLinePreviewGuid,
        figureGuid: this.getFigureGuid(),
        symbolType: FigureSymbolType.HELP_LINE,
        orientation,
        coordinate: orientation === Orientation.HORIZONTAL ? originalPoint.y : originalPoint.x,
        horizontal: null,
        vertical: null
      });

    this.foregroundLayer.activate();
    this.foregroundLayer.addChild(helpLinePreview);

    return helpLinePreview;
  }

  /**
   * Removes the help line preview from the canvas if it exists.
   *
   * @private
   */
  private removeHelpLinePreview() {
    const helpLinePreview = this.findOnCanvas(FigureModule.helpLinePreviewGuid);

    if (helpLinePreview) {
      helpLinePreview.remove();
    }
  }

  /**
   * Handles hit on symbol.
   * @param hitItem The hit paper.Item to be handled.
   * @param where The paper.Point on which the user has clicked on the canvas.
   * @private
   */
  private handleHitOnSymbol(hitItem: paper.Group, where: paper.Point): void {

    // Checks if user has hit on a segment to modify the line, arrow or brace
    this.handleHitOnSegment(hitItem, where);

    // Checks if user has hit on a curve handle to modify the curvature of the line
    if (isTypeCurve(hitItem)) {
      this.handleHitOnCurve(hitItem, where);
    }

    if (isTypeReferenceSignMarker(hitItem)) {
      this.handleHitOnReferenceSignMarker(where);
    }

    this.handleSelectionChange(hitItem);
  }

  /**
   * Change selection of an item when it was clicked on also depending on the CTRL-key.
   */
  private handleSelectionChange(item: paper.Group) {
    // if the clicked item is already selected, don't change selection
    let selectionMode = SelectionMode.KEEP;
    if (paper.Key.modifiers.control) {
      // multiselection: add or remove item to selection
      if (!item.selected) {
        selectionMode = SelectionMode.SELECT;
      } else {
        selectionMode = SelectionMode.UNSELECT;
      }
    } else if (!item.selected) {
      // select only this item
      selectionMode = SelectionMode.SELECT_EXCLUSIVELY;
    }

    // There are situations in which symbols are still selected in the view of paperjs but not in our FigureModule (reffered to as store)
    // This leads to the problem that the symbols can't be unselected and always move with other item without even any visual indication
    // The following code is a temporary workaround to counterfeit this issue, by propagating a selection to the FigureModule
    // The actual reason why this happens has to be fixed in the synchronisation algorithm
    if (item.selected && !FigureModule.selectedSymbols.includes(item)) {
      // mismatch detected
      selectionMode = SelectionMode.SELECT
      // In case the symbol has no group, create a new one
      // Otherwise it will be dropped when filterGroupsOnly is called
      if (item.className !== "Group") {
        item = new paper.Group(item);
      }
    }
    this.changeSelection(item, selectionMode);
  }

  /**
   * Handles hit on ReferenceRefrenceSignGroup.
   * Ungroups the reference signs after one second.
   * @param where The point where the user has clicked.
   * @private
   */
  private handleHitOnReferenceSignMarker(where: paper.Point) {
    this.logDebug('handleHitOnReferenceSignMarker', where);
    // You have to click and hold on a reference sign marker for 1sec without moving the mouse.
    // onMouseDown starts the timer, but onMouseUp or onMouseMove clear the timer.
    this.timerForUngrouping = setTimeout(() => {
      this.ungroupReferenceSignMarker(where);
    }, 1000);
  }

  /**
   * Handles hit on canvas to create a curve when the curve mode is active. Otherwise removes unfinished curves.
   * In any case clears the current selection in canvas.
   * @param where The hit paper.Point to be handled.
   * @private
   */
  private handleHitOnCanvas(where: paper.Point): void {
    this.logDebug('handleHitOnCanvas', where, this.isCreateCurveMode);
    // Check if user wants to create a new curve
    if (this.isCreateCurveMode) {
      this.createCurveSymbol(where);

      // Reselect the curve - this is for some reason necessary to show the handles of a partially created curve
      this.reselectCurve(where);
    } else {
      this.removeUnfinishedCurve();

      // Starts a new selection
      this.clearSelection();
    }
  }

  private reselectCurve(where: paper.Point) {
    this.clearSelection();
    const hitItem = this.getHitItem(where, isTypeCurve);
    if (!hitItem) {
      return;
    }
    this.selectItem(hitItem);
  }

  /**
   * Handles hit on selected segment.
   * @param hitItem The hit paper.Item to be handled.
   * @param where The paper.Point on which the user has clicked on the canvas.
   * @private
   */
  private handleHitOnSegment(hitItem: paper.Group, where: paper.Point): void {
    this.logDebug('handleHitOnSegment', hitItem, where);
    const hitResultSegment = hitItem.hitTest(where, SEGMENT_HIT_OPTIONS);
    if (hitResultSegment && hitResultSegment.type === 'segment') {
      this.segmentHitItem = hitItem;
      this.segmentHitSegment = hitResultSegment.segment;
    }
  }

  /**
   * Handles the hit on the curve ( 'handle-in','handle-out').
   * @param hitItem The hit paper.Item to be handled.
   * @param where The paper.Point on which the user has clicked on the canvas.
   * @private
   */
  private handleHitOnCurve(hitItem: paper.Group, where: paper.Point): void {
    this.logDebug('handleHitOnCurve', hitItem, where);
    const hitResultCurveHandle = hitItem.hitTest(where, CURVE_HANDLE_HIT_OPTIONS);
    if (!hitResultCurveHandle) {
      return;
    }

    if (hitResultCurveHandle.type === 'handle-in' || hitResultCurveHandle.type === 'handle-out') {
      setTimeout(() => {
        this.logDebug('handleHitOnCurve setting the currentCurve', hitItem);
        this.curveHandlesHitSegmentIndex = hitResultCurveHandle.segment.index;
        this.curveHandlesHitType = hitResultCurveHandle.type;
        this.currentCurve = hitItem;
        this.logDebug('handleHitOnCurve -> this.currentCurve =', hitItem);
      }, 1);
    }
  }

  /**
   * Draws the initial part (segment) of the curve.
   * @param where The paper.Point representing the position of the curve in canvas.
   * @private
   */
  private initializeCurve(where: paper.Point): void {
    this.logDebug('initializeCurve', where);
    const startPoint = this.figureCanvas!.getOriginalPoint(where);
    const curve = this.figureCanvas!.initializeCurve(
      {
        guid: this.createGuid(),
        figureGuid: this.getFigureGuid(),
        symbolType: FigureSymbolType.CURVE,
        x1: startPoint.x,
        y1: startPoint.y,
        dashed: this.figureEditorMode === FigureEditorMode.DASHED_CURVE
      });

    curve.data.isStartpointSet = true;
    if (!this.foregroundLayer.children.some((item) => item.name === curve.name)) {
      this.foregroundLayer.addChild(curve);
    }
    this.registerInChangeTracker(getSymbolGuidRequired(curve));
    // select the new curve to show its handles
    this.changeSelection(curve, SelectionMode.SELECT_EXCLUSIVELY);
    this.currentCurve = curve;
    this.logDebug('initializeCurve -> this.currentCurve =', curve);
  }

  /**
   * Draws the last part (segment) of the curve.
   * @param where The paper.Point where the user has clicked on the canvas to finalize the curve.
   * @private
   */
  private finalizeCurve(where: paper.Point): void {
    this.logDebug('finalizeCurve', where, this.currentCurve);

    if (!this.currentCurve) {
      return;
    }
    const endPoint = this.figureCanvas!.getOriginalPoint(where);
    this.currentCurve = this.figureCanvas!.finalizeCurve(this.currentCurve, endPoint);
    this.currentCurve.data.isEndpointSet = true;
    this.logDebug('finalizeCurve EndpointSet', endPoint, 'this.currentCurve =', this.currentCurve);

    if (this.figureEditorMode === FigureEditorMode.DASHED_CURVE) {
      this.currentCurve.dashArray = [4, 3];
    }
  }

  /**
   * Creates a curve figure symbol in two steps:
   * 1. if curve was not created yet, prepares a curve with one point (first click on canvas)
   * 2. if curve is already prepared, adds the endpoint to the curve (second click on canvas).
   * @param where The initial paper.Point of the curve in canvas.
   * @private
   */
  private createCurveSymbol(where: paper.Point): void {
    this.logDebug('createCurveSymbol', where, this.currentCurve,
                  this.currentCurve?.data?.isStartpointSet, this.currentCurve?.data?.isEndpointSet);
    // 1. If curve was not created yet, prepares a curve with one point (first click on canvas)
    if (!this.currentCurve) {
      this.initializeCurve(where);
    }
    // 2. If curve is already prepared, adds the endpoint to the curve (second click on canvas).
    else if (this.currentCurve && this.currentCurve.data.isStartpointSet && !this.currentCurve.data.isEndpointSet) {
      this.finalizeCurve(where);
    } else {
      this.removeCurve();
    }
  }

  /**
   * Gets the UI item that exists at the given point (see paper.Item#hitTest().
   *
   * @param point location to check for a hit
   * @return Item that was hit at the given point; if none: undefined
   * @private
   */
  private getHitItem(point: paper.Point, itemPredicate?: ItemPredicate): paper.Group | undefined {
    const items = this.filterHitItems(point);
    // First prefer selected items
    const selectedItem = items.find(item => item.selected);
    if (selectedItem) {
      return selectedItem;
    }
    let predicate = isTypeReferenceSignMarker;
    if (itemPredicate !== undefined) {
      predicate = itemPredicate;
    }
    // Second prefer reference signs
    const hitRefSignMarker = items.find(item => predicate(item));
    if (hitRefSignMarker) {
      return hitRefSignMarker;
    }
    return items.shift();
  }

  /**
   * Gets all UI items that exist at the given point (see paper.Item#hitTest().
   *
   * @param point location to check for a hit
   * @return Items that were hit at the given point
   * @private
   */
  private filterHitItems(point: paper.Point): paper.Group[] {
    const hitItems = this.foregroundLayer?.children
      .filter((childItem: paper.Item) => childItem.hitTest(point, CANVAS_HIT_OPTIONS));
    return filterOnlyGroups(hitItems);
  }

  /**
   * Finds all items that intersect with the reference item.
   *
   * @param refItem reference item
   * @return item-list that intersects the refItem
   * @private
   */
  private filterIntersectingItems(refItem: paper.Item): paper.Group[] {
    const items = this.foregroundLayer?.children
      .filter((testItem: paper.Item) => getSymbolGuid(testItem) !== getSymbolGuid(refItem)
        && refItem.bounds.intersects(testItem.bounds));
    return filterOnlyGroups(items);
  }

  /**
   * Moves an item based on client coordinates instead of screen coordinates.
   *
   * @param event The ToolEvent to be handled.
   * @private
   */
  private moveItem(event: paper.ToolEvent): void {
    const where = event.delta;
    if (where.isZero()) {
      return;
    }

    if (this.currentCurve && this.curveHandlesHitSegmentIndex != undefined && this.curveHandlesHitType) {
      this.lockItem(this.currentCurve);
      this.figureCanvas!.curveSegment(this.currentCurve, where, this.curveHandlesHitSegmentIndex, this.curveHandlesHitType);
    } else if (this.currentCurve && this.isCreateCurveMode) {
      this.lockItem(this.currentCurve);
      const segmentIndex = this.currentCurve.data.isEndpointSet ? 1 : 0;
      this.figureCanvas!.curveSegment(this.currentCurve, where, segmentIndex, 'handle-out');
    } else {
      this.moveItems(where, event.point);
    }
  }

  /**
   * Handler for MouseWheel event.
   *
   * @param event The WheelEvent to be handled.
   * @private
   */
  private onMouseWheel(event: WheelEvent): void {
    event.preventDefault();

    if (!paper.view) {
      return;
    }

    const newZoom = event.deltaY > 0 ? paper.view.zoom / MOUSE_ZOOM_FACTOR : paper.view.zoom * MOUSE_ZOOM_FACTOR;
    const mousePosition = new paper.Point(event.offsetX, event.offsetY);
    log.info(`onMousewheel: newZoom=${newZoom} event.deltaY=${event.deltaY} paper.view.zoom =${paper.view.zoom} MOUSE_ZOOM_FACTOR=${MOUSE_ZOOM_FACTOR}`);
    if (event.deltaY > 0) {
      if (!this.isValidZoomOut(newZoom)) {
        // Fits the figure within the current 100% zoom
        this.setZoom(this.current100ZoomFactor);

        // In this case, the zoom is not changed.
        this.isZoomChanged = false;
        return;
      }
    } else {
      if (!this.isValidZoomIn(newZoom)) {
        return;
      }
    }

    this.setZoomWithMousePositionFixed(mousePosition, newZoom);
  }

  /**
   * Sets the center point of the canvas based on the given mouse position and zooms it.
   *
   * @param point The new center point of the canvas after the zoom.
   * @param newZoom The new zoom factor to be applied on the canvas.
   * @private
   */
  private setZoomWithMousePositionFixed(point: paper.Point, newZoom: number): void {
    if (!paper.view) {
      return;
    }

    const oldZoom = paper.view.zoom;
    const beta = oldZoom / newZoom;

    //viewToProject: gives the coordinates in the Project space from the Screen Coordinates
    const viewPosition = paper.view.viewToProject(point);

    const mpos = viewPosition;
    const ctr = paper.view.center;

    const pc = mpos.subtract(ctr);
    const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

    log.info(`onMousewheelsetZoomWithMousePositionFixed: newZoom=${newZoom} oldZoom=${oldZoom}`);
    this.setZoom(newZoom);
    paper.view.center = paper.view.center.add(offset);
  }

  /**
   * Moves multiple items.
   *
   * @param delta The delta paper.Point representing the movement.
   * @param target The target point associated with the marker's movement
   * @private
   */
  private moveItems(delta: paper.Point, target: paper.Point): void {
    this.selectedSymbolsNeedSaving = true;
    this.foregroundLayer?.children.forEach((item: paper.Item) => {
      if (item.selected) {
        this.lockItem(item);

        // Fix: use mouse position for moving specific single symbols, otherwise reference sign markers might get stuck on help lines.
        item.position = (this.isSingleSelect() && (isTypeReferenceSignMarker(item) || isTypeHelpLine(item)))
          ? target : item.position.add(delta);

        if (isTypeReferenceSignMarker(item)) {
          this.figureCanvas!.onReferenceSignMarkerMoved(item, target, paper.view.zoom, this.isSingleSelect());
        } else if (isTypeHelpLine(item)) {
          this.figureCanvas!.onHelpLineMoved(item);
        } else if (isTypeLine(item) || isTypeArrow(item)) {
          this.figureCanvas!.onLineSymbolMoved(item, paper.view.zoom, this.isSingleSelect());
        } else if (isTypeBrace(item)) {
          this.figureCanvas!.onBraceMoved(item, paper.view.zoom, this.isSingleSelect());
        }
      }
    });
  }

  /**
   * Gets item's child of specified type.
   *
   * @return child item if one was found; undefined otherwise
   * @private
   */
  private getChildOfType(item: paper.Item, symbolType: FigureSymbolSubType): paper.Item | undefined {
    return this.filterChildrenOfType(item, symbolType).shift();
  }


  /**
   * Gets item's children of specified type.
   * @private
   */
  private filterChildrenOfType(item: paper.Item, symbolType: FigureSymbolSubType): paper.Item[] {
    return item.children
      .filter((childItem: paper.Item) => childItem.data.type === symbolType);
  }

  /**
   * Adapts the given symbol on movement.
   * Called for the symbols: Array and Brace.
   * @param event The ToolEvent to be handled.
   */
  private transformSymbol(event: paper.ToolEvent): void {
    if (!this.segmentHitSegment || !this.segmentHitItem) {
      return;
    }
    this.selectedSymbolsNeedSaving = true;
    this.lockItem(this.segmentHitItem);

    // Don't resize a brace if it would get too small!
    if (isTypeBrace(this.segmentHitItem) && this.isBraceTooSmall(this.segmentHitSegment, event.point)) {
      return;
    }
    // Don't allow moving segments of help lines.
    if (isTypeHelpLine(this.segmentHitItem)) {
      return;
    }
    // Don't allow moving segments of reference sign markers.
    if (isTypeReferenceSignMarker(this.segmentHitItem)) {
      return;
    }
    // Always move the selected segment (part of the helpline) along with the mouse pointer
    this.segmentHitSegment.point = event.point;
    // For arrows and braces do a partial re-draw
    if (isTypeArrow(this.segmentHitItem)) {
      this.transformArrow();
    }
    if (isTypeBrace(this.segmentHitItem)) {
      this.transformBrace();
    }
    if (isTypeLine(this.segmentHitItem) || isTypeArrow(this.segmentHitItem)) {
      this.figureCanvas!.onLineSegmentMoved(this.segmentHitItem, this.segmentHitSegment, paper.view.zoom);
    }
  }

  /**
   * Returns true, if the brace would get shorter than the minimal length (MIN_BRACE_LENGTH) when adjusted to the new mouse position.
   * @param braceSegment the helpline segment of the brace that is currently being modified
   * @param mousePosition the position of the mouse event
   */
  private isBraceTooSmall(braceSegment: paper.Segment, mousePosition: paper.Point): boolean {
    // The braceSegment refers to the path it belongs to consisting of two segments. Only consider the opposite segment!
    for (const segment of braceSegment.path.segments) {
      if (segment !== braceSegment && segment.point.subtract(mousePosition).length < MIN_BRACE_LENGTH) {
        return true;
      }
    }
    return false;
  }


  /**
   * Transforms the symbol arrow depending on the mouse movement.
   * @private
   */
  private transformArrow(): void {
    if (!this.segmentHitItem) {
      return;
    }

    // Adapts arrow head on movement
    const arrowHead = this.getChildOfType(this.segmentHitItem, FigureSymbolSubType.ARROW_HEAD) as paper.Path;
    const arrowTail = this.getChildOfType(this.segmentHitItem, FigureSymbolSubType.ARROW_TAIL) as paper.Path;
    if (arrowHead && arrowTail) {
      arrowHead.remove();
      this.segmentHitItem.addChild(this.figureCanvas!.drawArrowHead(arrowTail.segments[0].point, arrowTail.segments[1].point));
    }
  }

  /**
   * Transforms the symbol brace depending on the mouse movement.
   * @private
   */
  private transformBrace(): void {
    if (!this.segmentHitItem) {
      return;
    }

    // Adapts brace curves on movement
    const helpline = this.getChildOfType(this.segmentHitItem, FigureSymbolSubType.BRACE_HELPLINE) as paper.Path;
    this.segmentHitItem.children.forEach((childItem: paper.Item) => {
      if (childItem.data.type !== FigureSymbolSubType.BRACE_HELPLINE) {
        childItem.remove();
      }
    });

    if (!helpline) {
      return;
    }

    this.segmentHitItem.addChild(
      this.figureCanvas!.drawBrace(helpline.segments[0].point, helpline.segments[1].point, this.segmentHitItem.data.braceType));
  }

  /**
   * Draws a selection rectangle to wrap the selected items.
   * @param startPoint The start point of the selection.
   * @param endPoint The end point of the selection.
   * @private
   */
  private drawSelectionRectangle(startPoint: paper.Point, endPoint: paper.Point): void {
    this.selectionRectangle = new paper.Path.Rectangle(
      {
        ...SELECTION_RECTANGLE_STYLE,
        from: startPoint,
        to: endPoint,
      });
  }

  /**
   * Change selection of a paper.Group depending on the SelectionMode.
   * Allowed modes are: KEEP, UNSELECT, SELECT, SELECT_EXCLUSIVELY
   *
   * Items can use special selection features that can be configured by SelectionConfig (look for documentation there!).
   * @private
   */
  private changeSelection(group: paper.Group | paper.Item, selectionMode: SelectionMode) {
    if (selectionMode === SelectionMode.KEEP) {
      return;
    }
    if (selectionMode === SelectionMode.SELECT_EXCLUSIVELY) {
      this.clearSelection();
    }
    if (selectionMode === SelectionMode.UNSELECT) {
      this.unselectItem(group);
    }
    if (selectionMode === SelectionMode.SELECT || selectionMode === SelectionMode.SELECT_EXCLUSIVELY) {
      this.selectItem(group);
    }
  }

  /**
   * Removes the selection.
   * @private
   */
  clearSelection(): void {
    this.curveHandlesHitSegmentIndex = undefined;
    this.curveHandlesHitType = undefined;
    this.project?.selectedItems.forEach(item => this.figureCanvas!.selectItem(item, false));
    this.updateSelectedSymbolsInStore();
  }

  /**
   * Select a paper.Item representing a Symbol in the canvas.
   * @param item The paper.Item object to be selected.
   * @private
   */
  private selectItem(item: paper.Item): void {
    this.figureCanvas!.selectItem(item, true);
    this.updateSelectedSymbolsInStore();
  }

  /**
   * Unselect a paper.Item representing a Symbol in the canvas.
   * @param item The paper.Item object to be unselected.
   * @private
   */
  private unselectItem(item: paper.Item): void {
    this.figureCanvas!.selectItem(item, false);
    this.updateSelectedSymbolsInStore();
  }

  /**
   * Updates the list of selected symbols in the store.
   * @private
   */
  private updateSelectedSymbolsInStore(): void {
    const selectedSymbols = this.getSelectedSymbols();
    FigureModule.updateSelectedSymbols(selectedSymbols);
    // in case the state of FigureModule and the paper.js project are out of sync
    if (FigureModule.selectedSymbols.length === 0) {
      this.project.deselectAll();
      this.project.getItems({recursive: true}).forEach((item) => item.selected = false);
    }
  }

  /**
   * Return all currently selected symbols (filter only for paper.Groups).
   * @private
   */
  private getSelectedSymbols(): paper.Group[] {
    const selectedItems = this.project?.selectedItems;
    return !selectedItems ? [] : filterOnlyGroups(selectedItems);
  }

  /**
   * Check if a single symbol is selected.
   * @private
   * @returns True if exactly one symbol is selected, otherwise false.
   */
  private isSingleSelect(): boolean {
    return this.getSelectedSymbols().length === 1;
  }

  /**
   * Handler for KeyUp event.
   * @param event The KeyboardEvent to handle.
   * @private
   */
  private onKeyUp(event: KeyboardEvent): void {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    if (!this.hasFocus) {
      return; // dont do anything without focus
    }

    if (event.code === 'Delete') {
      this.removeSelected();
    }
  }

  /**
   * Remove selected symbols from backend and canvas.
   * @private
   */
  removeSelected() {
    const event = this.buildFigureSymbolsDeleteEvent(this.getSelectedSymbols());
    FigureModule.deleteFigureSymbols(event);

    this.getSelectedSymbols()
      .forEach((item: paper.Item) => {
        if (isTypeHelpLine(item)) {
          const orientation = item.data.orientation as Orientation;
          const dockedSymbols = item.data.dockedSymbols as string[];
          dockedSymbols.forEach(markerGuid => {
            const referenceSignMarker = this.findOnCanvas(markerGuid)!;
            switch (orientation) {
              case Orientation.HORIZONTAL:
                referenceSignMarker.data.horizontal = null;
                break;
              case Orientation.VERTICAL:
                referenceSignMarker.data.vertical = null;
                break;
              default: {
                const exhaustiveCheck: never = orientation;
                throw Error('unhandled orientation');
              }
            }
          });
        } else if (isTypeReferenceSignMarker(item)) {
          item.data.snapPoints
            .flatMap((it: MarkerSnapPoint) => findAttachedSymbols(this.foregroundLayer, it))
            .forEach((it: paper.Item) => detachAllSnapPoints(item, it));
        } else if (isTypeLine(item) || isTypeArrow(item) || isTypeBrace(item)) {
          item.data.snapPoints
            .flatMap((it: SymbolSnapPoint) => findAttachedMarker(this.foregroundLayer, it))
            .forEach((it: paper.Item) => {
              detachAllSnapPoints(it, item);
            });
        }
      });

    this.getSelectedSymbols().forEach(item => this.removeSymbolFromCanvas(getSymbolGuidRequired(item)!))

    this.updateSelectedSymbolsInStore()
  }

  /**
   * Resizes Curve segment in order to always guarantee a minimal length for the segments.
   * @param point The destination paper.Point of the curve in canvas.
   * @param handle The handle on the destination paper.Point of the curve in the canvas.
   * @private
   */
  private resizeCurveSegment(point: paper.Point, handle: paper.Point): void {
    if (point.getDistance(point.add(handle)) < MIN_SIZE_CURVE_HANDLE) {
      handle.length = MIN_SIZE_CURVE_HANDLE;
    }
  }

  private combineReferenceSignMarkers(marker1RefSignGuids: string[], marker2RefSignGuids: string[], marker1: paper.Group, marker2: paper.Group) {
    const combinedRefSignGuids: string[] = marker1RefSignGuids.concat(marker2RefSignGuids);

    this.updateReferenceSignMarkerToNewReferences(marker2, combinedRefSignGuids);
    const marker1Guid = getSymbolGuidRequired(marker1);

    FigureModule.removeSymbol(marker1Guid)
      .catch(useDefaultErrorHandling);

    this.removeSymbolFromCanvas(marker1Guid);
  }

  /**
   * Ungroup selected ReferenceSignMarker.
   * The sub-item that the user clicked on (at #start) will be split from the remaining ReferenceSignMarker.
   * @param where The point where the user has clicked.
   * @private
   */
  private ungroupReferenceSignMarker(where: paper.Point): void {
    const refSignMarker = this.getSelectedSymbols()
      .find((item: paper.Item) => isTypeReferenceSignMarker(item));

    if (refSignMarker) {
      // find REFERENCE_SIGN_MARKER sub-item that the user clicked on
      const clickedChild = this.filterChildrenOfType(refSignMarker, FigureSymbolSubType.REFERENCE_SIGN_TEXT)
        .find(child => child.hitTest(where));

      if (clickedChild) {
        // reference sign guid from the ungrouped item
        const ungroupedRefSignGuid = clickedChild.data.referenceSign.guid;
        // reference sign guids that remain in the original ReferenceSignMarker
        const remainingRefSignGuids = this.getReferenceSignGuids(refSignMarker)
          .filter(guid => guid !== ungroupedRefSignGuid);

        // ungroup only if the original reference sign marker contained at least 2 signs
        if (remainingRefSignGuids.length > 0) {
          // Update ReferenceSignMarker to changed reference signs in backend and UI.
          this.updateReferenceSignMarkerToNewReferences(refSignMarker, remainingRefSignGuids);

          // clear Selection so that new reference Sign will be the only selected item
          this.clearSelection();

          // add new ReferenceSignMarker with ungrouped reference sign
          const originalPoint = this.figureCanvas!.getOriginalPoint(clickedChild.position);
          const event = this.buildReferenceSignMarkerCreateEventOnDrop(
            [ungroupedRefSignGuid],
            refSignMarker.data.underlined,
            originalPoint,
            refSignMarker.data.snapPoints);

          this.addReferenceSignMarkerByCreateEvent([event]);

          // Add the new referenceSignMarkers to the backend.
          FigureModule.addReferenceSignMarker([event])
            .then(() => this.syncStoreToView(true))
            .catch(useDefaultErrorHandling);
        }
      }
    }
  }

  /**
   * Extract for a refernce sign marker the contained reference sign GUIDs.
   * @private
   */
  private getReferenceSignGuids(refSignMarkerItem: paper.Group): string[] {
    return this.filterChildrenOfType(refSignMarkerItem, FigureSymbolSubType.REFERENCE_SIGN_TEXT)
      .map(child => child.data.referenceSign.guid);
  }

  /**
   * Update ReferenceSignMarker to changed reference signs in backend and UI.
   * @private
   */
  private updateReferenceSignMarkerToNewReferences(refSignMarker: paper.Group,
                                                   newRefSignGuids: string[]) {
    const guid = getSymbolGuidRequired(refSignMarker);
    const originalPosition = this.figureCanvas!.getOriginalPoint(refSignMarker.position);
    const underlined = this.figureCanvas!.isReferenceSignMarkerUnderlined(refSignMarker);
    const newRefSignGuidsUnique = [...new Set(newRefSignGuids)];
    const newRefSigns: ReferredReferenceSign[] = buildReferredReferenceSignList(newRefSignGuidsUnique);

    // Update UI with modified ReferenceSignMarker.
    const updatedSymbol: ReferenceSignMarker = {
      guid: guid,
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.REFERENCE_SIGN_MARKER,
      topLeftX: originalPosition.x - (refSignMarker.data.boundingBox.getWidth() / 2),
      topLeftY: originalPosition.y - (refSignMarker.data.boundingBox.getHeight() / 2),
      bottomRightX: originalPosition.x + (refSignMarker.data.boundingBox.getWidth() / 2),
      bottomRightY: originalPosition.y + (refSignMarker.data.boundingBox.getHeight() / 2),
      referenceSigns: newRefSigns,
      underlined: underlined,
      vertical: refSignMarker.data.vertical,
      horizontal: refSignMarker.data.horizontal,
      snapPoints: refSignMarker.data.snapPoints
    };
    const item = this.updateSymbolOnCanvas(updatedSymbol);

    // Send UpdateSymbolEvent to backend.
    const updateEvent: UpdateReferenceSignMarkerEvent = {
      guid: guid,
      topLeftX: item.data.boundingBox.topLeftX,
      topLeftY: item.data.boundingBox.topLeftY,
      bottomRightX: item.data.boundingBox.bottomRightX,
      bottomRightY: item.data.boundingBox.bottomRightY,
      referenceSigns: newRefSignGuidsUnique,
      underlined: underlined,
      vertical: item.data.vertical,
      horizontal: item.data.horizontal,
      snapPoints: item.data.snapPoints
    };
    const updateSymbols = this.figureCanvas!.transFormSnapPoints(refSignMarker.data.snapPoints, item);
    const event = this.buildFigureSymbolsUpdateEvent(updateSymbols);
    event.referenceSignMarkers.push(updateEvent);

    FigureModule.updateFigureSymbols(event)
      .catch(useDefaultErrorHandling);
  }

  /**
   * Delete symbol on the canvas.
   *
   * @param guid identifying the symbol
   * @private
   */
  private removeSymbolFromCanvas(guid: string): void {
    const oldItem = this.findOnCanvas(guid);
    this.logDebug('removeSymbolFromCanvas', guid, oldItem);
    if (!oldItem) {
      this.deletionError.throwError([guid]);
    } else {
      oldItem.remove();
    }
    this.symbolViewChangeTracker.removeEntry(guid);
  }

  /**
   * Update symbol on the canvas to a new version.
   * Delete the old item from the canvas, then add a new one with #addSymbolToCanvas() preserving the selection mode.
   *
   * @param symbol model for the updated symbol
   * @private
   */
  private updateSymbolOnCanvas(symbol: FigureSymbol): paper.Item {
    const oldItem = this.findOnCanvas(symbol.guid);
    const selected = oldItem && oldItem.selected;
    oldItem?.remove();
    return this.addSymbolToCanvas(symbol, selected ? SelectionMode.SELECT : SelectionMode.KEEP);
  }

  /**
   * Find an item with a specific guid on the canvas.
   *
   * @param guid identifying the symbol
   * @return paper.Group or undefined
   * @private
   */
  private findOnCanvas(guid?: string): paper.Group | undefined {
    const item = this.foregroundLayer.children
      .find(it => getSymbolGuid(it) === guid);
    return asGroup(item);
  }

  /**
   * Every new symbol must be registered in the view change tracker so the diff between view and store change tracker is correct!
   * @private
   */
  private registerInChangeTracker(guid: string) {
    this.symbolViewChangeTracker.setEntryChangedDate(guid);
  }

  /**
   * Lock a view symbol so that it isn't overridden by the version in the store.
   * You should do this e.g. while a symbol is dragged by the user.
   * @private
   */
  private lockItem(item: paper.Item) {
    const guid = getSymbolGuid(item);
    if (guid) {
      this.symbolViewChangeTracker.lockEntry(guid);
    }
  }

  /**
   * Unlock a view symbol and trigger #syncStoreToView().
   * @private
   */
  private unlockItem(item: paper.Item) {
    const guid = getSymbolGuid(item);
    if (guid) {
      this.symbolViewChangeTracker.unlockEntry(guid);
      this.syncStoreToView();
    }
  }

  /**
   * Handler for ContextMenu event.
   *
   * Disables the context menu on the canvas.
   * @param event The MouseEvent to handle.
   * @private
   */
  private onContextMenu(event: MouseEvent): boolean {
    event.preventDefault();
    return false;
  }

  /**
   * Zoom in the canvas.
   * @public
   */
  zoomIn(): void {
    if (!paper.view) {
      return;
    }

    const newZoom = paper.view.zoom * BUTTON_ZOOM_FACTOR;
    if (!this.isValidZoomIn(newZoom)) {
      return;
    }

    this.setZoom(newZoom);
  }

  /**
   * Zoom out the canvas.
   * The zoom out function is allowed when the zoom factor is greater than the actual 100% factor.
   * The goal is to avoid use cases where the canvas becomes so small that makes it impossible to work with it.
   * @public
   */
  zoomOut(): void {
    if (!paper.view) {
      return;
    }

    const newZoom = paper.view.zoom / BUTTON_ZOOM_FACTOR;
    if (!this.isValidZoomOut(newZoom)) {
      // Fits the figure within the current 100% zoom
      this.setZoom(this.current100ZoomFactor);

      // In this case, the zoom is not changed.
      this.isZoomChanged = false;
    } else {
      this.setZoom(newZoom);
    }
  }

  /**
   * Checks whether the given zoom factor is within the allowed in zoom factor limits.
   * @param zoom The zoom factor to be checked.
   * @private
   */
  private isValidZoomIn(zoom: number): boolean {
    return zoom <= MAX_ZOOM;
  }

  /**
   * Checks whether the given zoom factor is within the allowed out zoom factor limits.
   * @param zoom The zoom factor to be checked.
   * @private
   */
  private isValidZoomOut(zoom: number): boolean {
    return zoom >= this.current100ZoomFactor;
  }

  /**
   * Centers the figure in the full size canvas.
   * @public
   */
  onFullZoomOut(): void {
    this.centerFigureInCanvas();

    // Fits the figure within the current 100% zoom
    this.setZoom(this.current100ZoomFactor);

    // In this case, the zoom is not changed.
    this.isZoomChanged = false;
  }

  /**
   * Centers the figure within the canvas.
   * @private
   */
  private centerFigureInCanvas(): void {
    if (!paper.view) {
      return;
    }

    paper.view.center = new paper.Point(this.width / 2, this.height / 2);
  }

  /**
   * Sets the given zoom factor on the paperjs canvas.
   * @param zoom The new zoom factor to be set.
   * @private
   */
  private setZoom(zoom: number): void {
    if (!paper.view) {
      return;
    }

    paper.view.zoom = zoom;
    this.isZoomChanged = true;
  }

  public setScaling(scaling: number, scaleContentArea: boolean): void {
    const layer = this.project.layers.find(x => x.name === "background");
    if (layer) {
      // reset scaling
      const image = layer.children[1];
      if (scaleContentArea) {
        // fit fullscreen
        image.fitBounds(layer.bounds);
        scaling = image.scaling.x < image.scaling.y ? image.scaling.x : image.scaling.y;
        // fit to content section
        image.fitBounds(this.project.layers[3].bounds);
        // calculate factor between those scalings
        scaling = (image.scaling.x < image.scaling.y ? image.scaling.x : image.scaling.y) / scaling;
        scaling = Math.round(scaling * 100) / 100;
      }
      // scale to 100 percent
      image.fitBounds(layer.bounds);
      // set new scaling
      image.scale(scaling);
    }
    // save change in store
    this.applicationFigure!.scaling = scaling;
    const guid = this.applicationFigure!.guid;
    // debounce change to backend
    if (guid) {
      this.debounceScaling({guid: guid, scaling: scaling})
        ?.catch(useDefaultErrorHandling);
    }
  }

  public setFigureNumberVisible(visible: boolean): void {
    const layer = this.project.layers.find(x => x.name === "figureInfoTexts");
    if (layer) {
      layer.visible = visible;
      // save change in store
      if (this.applicationFigure) {
        FigureModule.setShowFigureNumber(visible);
      }
    }
    const guid = this.applicationFigure?.guid;
    // debounce change to backend
    if (guid) {
      this.debounceFigureNumber({guid: guid, showFigureNumber: visible})
        ?.catch(useDefaultErrorHandling);
    }
  }

  private adjustCanvas(clientWidth: number, clientHeight: number): void {
    this.canvas.width = clientWidth;
    this.canvas.height = clientHeight;

    // Resizes the internal canvas size in paperjs to recenter everything
    paper.view.viewSize = new paper.Size(clientWidth, clientHeight);

    // When the zoom has been changed, it will be preserved. Otherwise, the figure dimensions will be resized accordingly
    if (!this.isZoomChanged) {
      this.onFullZoomOut();
    }
  }

  private get dimensions(): { width: number; height: number } {
    return {width: this.clientWidth, height: this.clientHeight};
  }

  @Watch("dimensions", {immediate: true})
  private dimensionsChanged(newDimensions: { width: number; height: number }): void {
    this.doNextTick(() => this.adjustCanvas(newDimensions.width, newDimensions.height));
  }

  private doNextTick(func: () => void): void {
    nextTick(() => {
      if (!paper.view) {
        return;
      }
      if (!this.canvas) {
        return;
      }
      if (!this.applicationFigure) {
        return;
      }
      func();
    });
  }

  /**
   * Called after the instance has been mounted.
   * @public
   */
  mounted(): void {
    this.logDebug('canvas mounted', this.applicationFigure)

    this.onApplicationFigureChanged();

    // Sets bigger handle size
    paper.settings.handleSize = 8;
  }

  /**
   * Called right before a Vue instance is destroyed.
   * @public
   */
  beforeUnmount(): void {
    this.logDebug('canvas beforeUnmount');
    this.removeListeners();
  }

  private removeListeners() {
    window.removeEventListener('keyup', this.onKeyUp);

    if (this.canvas) {
      this.canvas.removeEventListener('wheel', this.onMouseWheel);
      this.canvas.removeEventListener('contextmenu', this.onContextMenu);
    }

    if (this.figureCanvas !== null) {
      this.figureCanvas.clear();
      this.figureCanvas = null;
      FigureModule.activateSelectionMode();
    }

    if (this.tool) {
      this.tool.remove();
    }
  }

  private logDebug(text: string, ...object: any) {
    if (this.debug) {
      console.log(text, object);
    }
  }

  /**
   * Constructs an event for bulk-creates from a list of items.
   * This method categorizes each item into help lines, reference sign markers, or symbols
   * and builds the corresponding create-events for each category.
   *
   * @param items The list of items to process
   * @param offset The offset for pasted symbols
   * @returns The constructed event containing the create-events
   */
  private buildFigureSymbolsCreateEvent(items: paper.Item[], offset: number): CreateApplicationFigureSymbolsEvent {
    const event: CreateApplicationFigureSymbolsEvent = {
      helpLines: [],
      referenceSignMarkers: [],
      symbols: []
    };
    return items.reduce((acc, item) => {
      if (isTypeHelpLine(item)) {
        acc.helpLines.push(this.buildSymbolEventForPastingHelpLines(item, offset));
      } else if (isTypeReferenceSignMarker(item)) {
        const event = this.calculateCurrentVersionofRefSign(item, offset);
        if (event) {
          acc.referenceSignMarkers.push();
        }
      } else if (isTypePaletteSymbol(item)) {
        if (isTypeBrace(item)) {
          acc.symbols.push(this.buildSymbolEventForPastingBraces(item, offset));
        } else {
          const event = this.buildSymbolEventForPasteObjects(item, offset);
          if (event) {
            acc.symbols.push(event);
          }
        }
      }
      return acc;
    }, event);
  }

  /**
   * Constructs an event for bulk-updates from a list of items.
   * This method categorizes each item into help lines, reference sign markers, or symbols
   * and builds the corresponding update-events for each category.
   *
   * @param items The list of items to process
   * @returns The constructed event containing the update-events
   */
  private buildFigureSymbolsUpdateEvent(items: paper.Group[]): UpdateApplicationFigureSymbolsEvent {
    const event: UpdateApplicationFigureSymbolsEvent = {
      helpLines: [],
      referenceSignMarkers: [],
      symbols: []
    };
    return items.reduce((acc, item) => {
      if (isTypeHelpLine(item)) {
        acc.helpLines.push(this.buildHelpLineUpdateEvent(item));
      } else if (isTypeReferenceSignMarker(item)) {
        acc.referenceSignMarkers.push(this.buildReferenceSignMarkerUpdateEvent(item));
      } else if (isTypePaletteSymbol(item)) {
        if (isTypeLine(item) || isTypeArrow(item)) {
          acc.symbols.push(this.buildLineOrArrowUpdateEvent(item));
        }
        if (isTypeBrace(item) || isTypeCurve(item)) {
          acc.symbols.push(this.buildBraceOrCurveUpdateEvent(item));
        }
      }
      return acc;
    }, event);
  }

  /**
   * Constructs an event for bulk-deletes from a list of items.
   * This method categorizes each item into help lines, reference sign markers, or symbols
   * and builds the corresponding delete-events for each category.
   *
   * @param items The list of items to process
   * @returns The constructed event containing the delete-events
   */
  private buildFigureSymbolsDeleteEvent(items: paper.Group[]): DeleteApplicationFigureSymbolsEvent {
    const event: DeleteApplicationFigureSymbolsEvent = {
      helpLineGuids: [],
      referenceSignMarkerGuids: [],
      symbolGuids: []
    };
    return items.reduce((acc, item) => {
      if (isTypeHelpLine(item)) {
        acc.helpLineGuids.push(getSymbolGuidRequired(item));
      } else if (isTypeReferenceSignMarker(item)) {
        acc.referenceSignMarkerGuids.push(getSymbolGuidRequired(item));
      } else if (isTypePaletteSymbol(item)) {
        if (!(item.data.isStartpointSet && !item.data.isEndpointSet)) {
          acc.symbolGuids.push(getSymbolGuidRequired(item));
        }
      }
      return acc;
    }, event);
  }

  /**
   * Generates a create-event for each symbol figure type for the method onDrop.
   *
   * @param symbolType The type of Symbol to be set
   * @param eventData The entry containing the properties to be set on the resulting create-event object
   * @param originalPoint The paper.Point to be set as start point in the create-event object to be returned
   * @return The resulting create-event containing all new properties.
   * @private
   */
  private buildCreateSymbolEvent(symbolType: FigureSymbolType,
                                 eventData: ReferenceSignListEntry | SymbolsListEntry | HelpLineListEntry,
                                 originalPoint: paper.Point): CreateSymbolEvent | CreateReferenceSignMarkerEvent | CreateHelpLineEvent {
    // For time being, figures only have one layer
    // Adds additional properties by given symbol type
    switch (symbolType) {
      case FigureSymbolType.LINE:
      case FigureSymbolType.ARROW: {
        const symbolData = eventData as SymbolsListEntry;
        return this.buildLineOrArrowCreateEventOnDrop(symbolType, symbolData, originalPoint);
      }
      case FigureSymbolType.BRACE: {
        const symbolData = eventData as SymbolsListEntry;
        return this.buildBraceCreateEventOnDrop(symbolData.braceType, originalPoint);
      }
      case FigureSymbolType.CURVE: {
        const symbolData = eventData as SymbolsListEntry;
        return this.buildCurveCreateEventOnDrop(symbolData, originalPoint);
      }
      case FigureSymbolType.REFERENCE_SIGN_MARKER: {
        const referenceSignData = eventData as ReferenceSignListEntry;
        return this.buildReferenceSignMarkerCreateEventOnDrop(
          [referenceSignData.guid],
          false,
          originalPoint,
          []);
      }
      case FigureSymbolType.HELP_LINE: {
        const helpLineData = eventData as HelpLineListEntry;
        return this.buildHelpLineCreateEventOnDrop(helpLineData, originalPoint);
      }
      default:
        throw new Error("Illegal symbol type: " + symbolType);
    }
  }

  /**
   * Gets the required properties from an item (paper js) to create a create-event for a line or arrow symbol.
   *
   * @param symbolType
   * @param eventData The entry containing the properties to be set on the resulting create-event object
   * @param originalPoint The paper.Point to be set as end point on the create-event object to be returned
   * @return The resulting create-event containing all new properties
   * @private
   */
  private buildLineOrArrowCreateEventOnDrop(symbolType: FigureSymbolType,
                                            eventData: SymbolsListEntry,
                                            originalPoint: paper.Point): CreateSymbolEvent {
    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: symbolType,
      x1: originalPoint.x - LINE_LENGTH / 2,
      y1: originalPoint.y,
      x2: originalPoint.x + LINE_LENGTH / 2,
      y2: originalPoint.y,
      dashed: eventData.dashed
    };
  }

  /**
   * Gets the required properties from an item (paper js) to create a create-event for a brace symbol.
   *
   * @param braceType Type to be set on the resulting create-event object
   * @param originalPoint The paper.Point to be set as end point on the create-event object to be returned
   * @return The resulting create-event containing all new properties
   * @private
   */
  private buildBraceCreateEventOnDrop(braceType: BraceType, originalPoint: paper.Point): CreateSymbolEvent {
    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.BRACE,
      x1: originalPoint.x - BRACE_SIZE / 2,
      y1: originalPoint.y,
      x2: originalPoint.x + BRACE_SIZE / 2,
      y2: originalPoint.y,
      braceType: braceType
    };
  }

  /**
   * Gets the required properties from an item (paper js) to create a create-event for a curve symbol.
   *
   * @param eventData The entry containing the properties to be set on the resulting create-event object
   * @param originalPoint The paper.Point to be set as end point on the create-event object to be returned
   * @return The resulting CreateSymbolEvent containing all new properties
   * @private
   */
  private buildCurveCreateEventOnDrop(eventData: SymbolsListEntry, originalPoint: paper.Point): CreateSymbolEvent {
    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.CURVE,
      x1: originalPoint.x - DEFAULT_CURVE.WIDTH / 2,
      y1: originalPoint.y + DEFAULT_CURVE.HEIGHT / 2,
      x2: originalPoint.x + DEFAULT_CURVE.WIDTH / 2,
      y2: originalPoint.y - DEFAULT_CURVE.HEIGHT / 2,
      handle1x: DEFAULT_CURVE.HANDLE1X,
      handle1y: DEFAULT_CURVE.HANDLE1Y,
      handle2x: DEFAULT_CURVE.HANDLE2X,
      handle2y: DEFAULT_CURVE.HANDLE2Y,
      dashed: eventData.dashed
    };
  }

  /**
   * Gets the required properties from an item (paper js) to create a create-event for a reference sign marker symbol.
   *
   * @param guids The guids of the reference signs
   * @param underlined If the reference sign marker shall be underlined
   * @param originalPoint The paper.Point to be set as end point on the create-event object to be returned
   * @return The resulting create-event containing all new properties
   * @private
   */
  private buildReferenceSignMarkerCreateEventOnDrop(guids: string[],
                                                    underlined: boolean,
                                                    originalPoint: paper.Point,
                                                    snapPoints: MarkerSnapPoint[]): CreateReferenceSignMarkerEvent {
    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.REFERENCE_SIGN_MARKER,
      topLeftX: originalPoint.x,
      topLeftY: originalPoint.y,
      bottomRightX: originalPoint.x,
      bottomRightY: originalPoint.y,
      referenceSigns: guids,
      underlined: underlined,
      snapPoints: snapPoints
    };
  }

  /**
   * Gets the required properties from an item (paper js) to create a create-event for a help line symbol.
   *
   * @param eventData The entry containing the properties to be set on the resulting create-event object
   * @param originalPoint The paper.Point to be set as end point on the create-event object to be returned
   * @return The resulting create-event containing all new properties
   * @private
   */
  private buildHelpLineCreateEventOnDrop(eventData: HelpLineListEntry, originalPoint: paper.Point): CreateHelpLineEvent {
    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.HELP_LINE,
      orientation: eventData.orientation,
      coordinate: eventData.orientation === Orientation.HORIZONTAL ? originalPoint.y : originalPoint.x
    };
  }

  /**
   * Constructs an update event from a given item.
   * This method calculates the original position of the item and determines the bounding box coordinates,
   * along with additional properties such as reference signs and alignment indicators.
   *
   * @param item The item to process and convert into an update event
   * @returns The constructed update event containing details about the reference sign marker
   */
  private buildReferenceSignMarkerUpdateEvent(item: paper.Group): UpdateReferenceSignMarkerEvent {
    const originalPosition = this.figureCanvas!.getOriginalPoint(item.position);
    return {
      guid: getSymbolGuidRequired(item),
      topLeftX: originalPosition.x - (item.data.boundingBox.getWidth() / 2),
      topLeftY: originalPosition.y - (item.data.boundingBox.getHeight() / 2),
      bottomRightX: originalPosition.x + (item.data.boundingBox.getWidth() / 2),
      bottomRightY: originalPosition.y + (item.data.boundingBox.getHeight() / 2),
      referenceSigns: this.getReferenceSignGuids(item),
      underlined: item.data.underlined,
      vertical: item.data.vertical,
      horizontal: item.data.horizontal,
      snapPoints: item.data.snapPoints
    };
  }

  /**
   * Constructs an update event from a given item.
   * This method calculates the original position of the item and determines the orientation and coordinate
   * for the help line update event.
   *
   * @param item The item to process and convert into an update event
   * @returns The constructed update event containing details about the help line
   */
  private buildHelpLineUpdateEvent(item: paper.Item): UpdateHelpLineEvent {
    const originalPosition = this.figureCanvas!.getOriginalPoint(item.position);
    return {
      guid: getSymbolGuidRequired(item),
      orientation: item.data.orientation,
      coordinate: item.data.orientation === Orientation.VERTICAL ? originalPosition.x : originalPosition.y
    };
  }

  /**
   * Creates an event for a line or arrow based on new properties due to a movement event.
   *
   * @param item The paper.Item object representing an Arrow or a Line in the canvas
   * @return The corresponding event object compound from the given information
   * @private
   */
  private buildLineOrArrowUpdateEvent(item: paper.Item): UpdateSymbolEvent {
    const isLineOrArrowOrBrace = (child: paper.Item) =>
      child.data.type === FigureSymbolSubType.LINE_PATH ||
      child.data.type === FigureSymbolSubType.ARROW_TAIL;
    const subItem = item.children.find(isLineOrArrowOrBrace) as paper.Path;

    const startpoint = subItem.firstSegment.point;
    const endpoint = subItem.lastSegment.point;
    const originalStartPosition = this.figureCanvas!.getOriginalPoint(startpoint);
    const originalEndPosition = this.figureCanvas!.getOriginalPoint(endpoint);
    const dashed = item.data.dashed;

    return {
      guid: getSymbolGuidRequired(item),
      x1: originalStartPosition.x,
      y1: originalStartPosition.y,
      x2: originalEndPosition.x,
      y2: originalEndPosition.y,
      dashed: dashed,
      snapPoints: item.data.snapPoints
    };
  }

  /**
   * Posts an update-event for a curve or brace based on new properties due to being moved.
   *
   * @param item The paper.Item object representing a Curve in the canvas
   * @return The corresponding update-event object compound from the given information
   * @private
   */
  private buildBraceOrCurveUpdateEvent(item: paper.Item): UpdateSymbolEvent {
    const isBraceOrCurve = (child: paper.Item) =>
      child.data.type === FigureSymbolSubType.BRACE_HELPLINE ||
      child.data.type === FigureSymbolSubType.CURVE_PATH;
    const subItem = item.children.find(isBraceOrCurve) as paper.Path;

    const startPoint = subItem.firstSegment.point;
    const endPoint = subItem.lastSegment.point;
    const originalStartPosition = this.figureCanvas!.getOriginalPoint(startPoint);
    const originalEndPosition = this.figureCanvas!.getOriginalPoint(endPoint);
    const braceType = item.data.braceType;
    const dashed = item.data.dashed;

    return {
      guid: getSymbolGuidRequired(item),
      x1: originalStartPosition.x,
      y1: originalStartPosition.y,
      x2: originalEndPosition.x,
      y2: originalEndPosition.y,
      braceType: braceType,
      dashed: dashed,
      snapPoints: item.data.snapPoints
    };
  }

  private buildSymbolEventForPasteObjects(item: paper.Item, offset: number): CreateSymbolEvent | undefined {
    const path: any = item.children[0];

    if (path) {
      const start = this.figureCanvas!.getOriginalPoint(path.segments[0].point);
      const end = this.figureCanvas!.getOriginalPoint(path.segments[1].point);
      const handle1 = this.figureCanvas!.getOriginalPoint(path.segments[0].handleIn);
      const handle2 = this.figureCanvas!.getOriginalPoint(path.segments[1].handleIn);

      return {
        guid: this.createGuid(),
        figureGuid: this.getFigureGuid(),
        symbolType: item.data.type,
        x1: start.x + offset,
        y1: start.y + offset,
        x2: end.x + offset,
        y2: end.y + offset,
        handle1x: handle1.x,
        handle1y: handle1.y,
        handle2x: handle2.x,
        handle2y: handle2.y,
        dashed: item.data.dashed
      };
    }
  }

  private buildSymbolEventForPastingBraces(item: paper.Item, offset: number): CreateSymbolEvent {
    const path: any = item.children[0];
    const start = this.figureCanvas!.getOriginalPoint(path.segments[0].point);
    const end = this.figureCanvas!.getOriginalPoint(path.segments[1].point);

    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.BRACE,
      x1: start.x + offset,
      y1: start.y + offset,
      x2: end.x + offset,
      y2: end.y + offset,
      braceType: item.data.braceType,
      snapPoints: item.data.snapPoints
    };
  }

  private buildSymbolEventForPastingHelpLines(item: paper.Item, offset: number): CreateHelpLineEvent {
    const originalPoint = this.figureCanvas!.getOriginalPoint(item.position);
    return {
      guid: this.createGuid(),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.HELP_LINE,
      orientation: item.data.orientation,
      coordinate: item.data.orientation === Orientation.HORIZONTAL ? originalPoint.y + offset : originalPoint.x + offset
    };
  }

  /**
   * Creates create-event based on the properties of the given paper.Path object representing a Curve in the canvas.
   *
   * @param curveGroup The group representing a Curve in the canvas
   * @return The create-event object compound from the given information
   * @private
   */
  private buildCurveCreateSymbolEvent(curveGroup: paper.Group): CreateSymbolEvent {
    const path = this.getChildOfType(curveGroup, FigureSymbolSubType.CURVE_PATH) as paper.Path;
    const start = this.figureCanvas!.getOriginalPoint(path.firstSegment.point);
    const end = this.figureCanvas!.getOriginalPoint(path.lastSegment.point);
    const handle1 = this.figureCanvas!.getOriginalPoint(path.firstSegment.handleIn);
    const handle2 = this.figureCanvas!.getOriginalPoint(path.lastSegment.handleIn);

    this.resizeCurveSegment(start, handle1);
    this.resizeCurveSegment(end, handle2);

    return {
      guid: getSymbolGuidRequired(curveGroup),
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.CURVE,
      x1: start.x,
      y1: start.y,
      x2: end.x,
      y2: end.y,
      handle1x: handle1.x,
      handle1y: handle1.y,
      handle2x: handle2.x,
      handle2y: handle2.y,
      dashed: this.figureEditorMode === FigureEditorMode.DASHED_CURVE
    };
  }

  /**
   * Creates update-event based on the properties of the path.
   *
   * @param curveGroup The group object representing a curve in the canvas
   * @return The update-event object compound from the given information
   * @private
   */
  private buildCurveUpdateSymbolEvent(curveGroup: paper.Group): UpdateSymbolEvent {
    const guid = getSymbolGuidRequired(curveGroup);
    const dashed = curveGroup.data.dashed;
    const path = this.getChildOfType(curveGroup, FigureSymbolSubType.CURVE_PATH) as paper.Path;

    const start = this.figureCanvas!.getOriginalPoint(path.firstSegment.point);
    const end = this.figureCanvas!.getOriginalPoint(path.lastSegment.point);
    const handle1 = this.figureCanvas!.getOriginalPoint(path.firstSegment.handleIn);
    const handle2 = this.figureCanvas!.getOriginalPoint(path.lastSegment.handleIn);

    this.resizeCurveSegment(start, handle1);
    this.resizeCurveSegment(end, handle2);

    return {
      guid: guid,
      x1: start.x,
      y1: start.y,
      x2: end.x,
      y2: end.y,
      handle1x: handle1.x,
      handle1y: handle1.y,
      handle2x: handle2.x,
      handle2y: handle2.y,
      dashed: dashed,
      snapPoints: curveGroup.data.snapPoints
    };
  }

  /**
   * Build a curve symbol.
   *
   * @param curveGroup The group object representing a curve in the canvas
   * @return Curve object from the given paper.Path if a curve is actually being created in the canvas and undefined otherwise
   * @private
   */
  private buildCurveSymbol(curveGroup: paper.Group): Curve {
    const guid = getSymbolGuidRequired(curveGroup);
    const dashed = curveGroup.data.dashed;
    const path = this.getChildOfType(curveGroup, FigureSymbolSubType.CURVE_PATH) as paper.Path;

    const start = this.figureCanvas!.getOriginalPoint(path.firstSegment.point);
    const end = this.figureCanvas!.getOriginalPoint(path.lastSegment.point);
    const handle1 = this.figureCanvas!.getOriginalPoint(path.firstSegment.handleIn);
    const handle2 = this.figureCanvas!.getOriginalPoint(path.lastSegment.handleIn);

    this.resizeCurveSegment(start, handle1);
    this.resizeCurveSegment(end, handle2);

    return {
      figureGuid: this.getFigureGuid(),
      symbolType: FigureSymbolType.CURVE,
      x1: start.x,
      y1: start.y,
      x2: end.x,
      y2: end.y,
      handle1x: handle1.x,
      handle1y: handle1.y, // handel1.x + minDistance
      handle2x: handle2.x,
      handle2y: handle2.y,
      guid: guid,
      dashed: dashed,
      snapPoints: []
    }
  }
}

export default toNative(Canvas);
export {Canvas};
</script>

<style lang="scss" scoped>

.canvas-container {

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  overflow-y: hidden;
  overflow-x: auto;

  #figureEditorCanvas {
    width: 100%;
    height: 100%;
  }
}

.canvas-drop {
  width: fit-content;
  height: fit-content;
}
</style>
