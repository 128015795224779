import {MarkerSnapPoint} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';

export enum StemFormOrigin {
    /**
     * Indicates that a StemForm has been created by a User
     */
    GENERATED = 'GENERATED',
    /**
     * Indicates that a StemForm has been automatically generated
     */
    MANUAL = 'MANUAL'
}

export interface StemForm {
    guid: string | null;
    stem: string;
    stemFormOrigin: StemFormOrigin;
}

export interface CreateReferenceSignEvent {
    // For sending to the server
    applicationDocument: string; // Using guid
    label?: string;
    name: string;
}

export interface StemFormMatch {
    matchWord: string;
    collisionWithOtherReferenceSign: boolean;
}

export interface UpdateReferenceSignEvent extends CreateReferenceSignEvent {
    guid: string;
    stemForms?: Array<StemForm>;
    excludeStemFormMatches?: Array<string>;
}

export interface DeleteReferenceSignEvent {
    referenceSign: ReferenceSign;
}

export interface ReferenceSign {
    guid?: string;
    applicationDocument: string; // Using guid
    label?: string | null;
    name: string;
    stemForms?: Array<StemForm>;
    excludeStemFormMatches?: Array<string>;
    stemFormMatches?: Array<StemFormMatch>;
    numberMatchesOnReferenceSign: number;
}

export interface ReferenceSignMarkerPrototype {
    topLeftX: number;
    topLeftY: number;
    bottomRightX: number;
    bottomRightY: number;
    referenceSigns: BasicReferenceSign[];
    underlined: boolean;
    snapPoints?: MarkerSnapPoint[];
}

export interface BasicReferenceSign {
    name: string;
    stemForms: Array<StemForm>;
    excludedStems: string[];
}

export interface ReferenceSignCreatedVmUpdate {
    referenceSign: ReferenceSign;
}

export type ReferenceSignUpdatedVmUpdate = ReferenceSignCreatedVmUpdate;
